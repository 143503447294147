var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'antd';
// import { useEffect, useState } from 'react'
import styles from './confirm-modal.module.scss';
var Component = function (props) {
    var open = props.open, onConfirm = props.onConfirm, id = props.id, title = props.title, description = props.description, onCancel = props.onCancel;
    var confirmButtonPressed = function () {
        onConfirm(id);
    };
    return (_jsx(Modal, __assign({ centered: true, className: styles.root, visible: open, title: title, onOk: function () { return confirmButtonPressed(); }, okText: 'Verwijderen', onCancel: function () { return onCancel(); } }, { children: description }), void 0));
};
export default Component;
