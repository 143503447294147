var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RealisationStore as CustomerRealisationStore } from './customer-realisation.store';
import { loaderStore } from 'shared/loader';
import styles from './customer-realisation.module.scss';
import { Button } from 'antd';
import { useParams } from 'react-router';
import { PlusCircleOutlined } from '@ant-design/icons';
import AnnualrealisationInputSheet, { Mode, TableType } from 'organisations/annualrealisation-input-sheet/annualrealisation-input-sheet';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfirmModal } from 'shared/confirm-modal';
import { getSearchParams } from 'utilities/helpers/url';
import { authStore } from 'auth';
var Component = function () {
    var store = CustomerRealisationStore;
    var history = useHistory();
    var _a = useParams(), year = _a.year, afasid = _a.afasid;
    useEffect(function () {
        store.afasid = afasid;
        store.setParams(afasid, (parseInt(year))).then();
        store.setHistory(history).then();
        var params = getSearchParams(history);
        var tab = params.get('tab');
        store.setSelectedTab(tab !== null && tab !== void 0 ? tab : '1');
    }, [store, history, year, afasid]);
    // If the annualrealisation is no longer allowed to be edited, force unset cookie
    if (store.annualrealisation.blockInputRealisation) {
        authStore.forceForbiddenCustomerRealisation();
    }
    return store.afasid.length === 0 ? _jsx(_Fragment, {}, void 0) : (_jsxs("div", __assign({ className: styles.root }, { children: [!store.annualrealisationExists ? (_jsxs("div", __assign({ className: styles['no-annualrealisation-yet-content-wrapper'] }, { children: [_jsx("p", { children: "Er is voor deze entiteit nog geen mededeling aangemaakt voor deze jaargang" }, void 0), _jsx(Button, __assign({ type: "primary", shape: "round", icon: _jsx(PlusCircleOutlined, {}, void 0), onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, store.createNewAnnualrealisation()];
                                case 1: return [2 /*return*/, _a.sent()];
                            }
                        }); }); } }, { children: "Nieuwe Medeling Aanmaken voor deze Jaargang" }), void 0)] }), void 0)) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles['header-wrapper'] }, { children: [_jsx("div", __assign({ className: styles['organisation-name'] }, { children: "Realisatieoverzicht " + store.organisation.name }), void 0), _jsx("div", __assign({ className: styles['navigation-wrapper'] }, { children: _jsx(Button, __assign({ disabled: store.customerAccepted, type: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, store.setCustomerAccepted()];
                                            case 1: return [2 /*return*/, _a.sent()];
                                        }
                                    }); }); } }, { children: "Akkoord" }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: styles['realisation-content-wrapper'] }, { children: loaderStore.loading ? 'De gegevens worden opgehaald...' : (_jsxs("div", __assign({ className: styles['realisation-form-wrapper'] }, { children: [_jsx("div", __assign({ className: styles['table-title'] }, { children: "Uren" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.Hours, rowsTitle: "Projecten", tableData: store.tableData, customerAccepted: store.customerAccepted, grantedTotal: store.annualrealisation.grantedHours, realisedTotal: store.annualrealisation.realisedHours, reportedTotal: store.annualrealisation.reportedHours, highlightReportedTotal: true, entitledTotal: store.annualrealisation.entitledHours, entitledTotalErrors: store.annualrealisationErrors.entitledHours, filterProject: function (pf) { return store.setProjectFilter(pf); }, filterValue: store.projectFilter, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                            case 1: return [2 /*return*/, _a.sent()];
                                        }
                                    }); }); }, mode: Mode.CustomerRealisation, grantedFieldReadOnly: true }, void 0), store.annualrealisation.forfait ? (_jsx(_Fragment, {}, void 0)) : (_jsxs("span", { children: [_jsx("div", __assign({ className: styles['table-title'] }, { children: "Kosten" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.Costs, rowsTitle: "Projecten", tableData: store.tableData, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            }); }); }, customerAccepted: store.customerAccepted, grantedTotal: store.annualrealisation.grantedCosts, realisedTotal: store.annualrealisation.realisedCosts, filterProject: function (pf) { return store.setProjectFilter(pf); }, filterValue: store.projectFilter, reportedTotal: store.annualrealisation.reportedCosts, mode: Mode.CustomerRealisation, grantedFieldReadOnly: true }, void 0), _jsx("div", __assign({ className: styles['table-title'] }, { children: "Uitgaven" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.Expenses, rowsTitle: "Projecten", tableData: store.tableData, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            }); }); }, customerAccepted: store.customerAccepted, grantedTotal: store.annualrealisation.grantedExpenses, realisedTotal: store.annualrealisation.realisedExpenses, filterProject: function (pf) { return store.setProjectFilter(pf); }, filterValue: store.projectFilter, reportedTotal: store.annualrealisation.reportedExpenses, mode: Mode.CustomerRealisation, grantedFieldReadOnly: true }, void 0), store.annualrealisation.isBigExpensesAmountEnabled && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles['table-title'] }, { children: "Uitgaven > 1 mln." }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.BigExpenses, tableData: store.bigExpensesTableData, hideTotalRows: true, onInputChange: function (key, e, r) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, store.onInputChange(key, Number(e.target.value), r.projectId)];
                                                            case 1: return [2 /*return*/, _a.sent()];
                                                        }
                                                    }); }); }, mode: Mode.CustomerRealisation, grantedFieldReadOnly: true }, void 0)] }, void 0)), _jsx("div", __assign({ className: styles['table-title'] }, { children: "Totaal Kosten & Uitgaven" }), void 0), _jsx(AnnualrealisationInputSheet, { applications: store.applications, type: TableType.CostsExpenses, grantedTotal: store.annualrealisation.grantedCostsExpenses, realisedTotal: store.annualrealisation.realisedCostsExpenses, reportedTotal: store.annualrealisation.reportedCostsExpenses, entitledTotal: store.annualrealisation.entitledCostsExpenses, entitledTotalErrors: store.annualrealisationErrors.entitledCostsExpenses, mode: Mode.CustomerRealisation, grantedFieldReadOnly: true }, void 0)] }, void 0))] }), void 0)) }), void 0)] }, void 0)), _jsx(ConfirmModal, { open: store.selectedProjectId.length > 0, title: "Project verwijderen", description: "Weet je zeker dat je dit project wil verwijderen? Dit is niet alleen deze rij maar alle rijen van dit project in de tabellen uren, kosten en uitgaven met de bijbehorende realisaties", onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, store.deleteProject()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                }); }); }, onCancel: function () { return store.setSelectedProjectId(''); }, id: store.selectedProjectId }, void 0)] }), void 0));
};
export default Component;
