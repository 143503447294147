var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { notification } from 'antd';
import Axios from 'axios';
import { tokenManager } from 'utilities';
import { environment } from 'utilities/environment';
var dataApi = Axios.create({
    baseURL: environment.getValue('REACT_APP_SERVER_URL') + '/api',
    responseType: 'json'
});
var refreshApi = Axios.create({
    baseURL: environment.getValue('REACT_APP_SERVER_URL') + '/api',
    responseType: 'json'
});
var decoder = new TextDecoder('utf8');
var errorResponseInterceptor = function (error) {
    var _a, _b, _c;
    var errorText = 'Unknown error';
    if (error.response) {
        if (error.response.data instanceof ArrayBuffer) {
            var decodedString = new DataView(error.response.data);
            var obj = JSON.parse(decoder.decode(decodedString));
            errorText = obj.error;
        }
        else {
            var responseData = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
            var errorMessage = responseData || (responseData === null || responseData === void 0 ? void 0 : responseData.message) || (responseData === null || responseData === void 0 ? void 0 : responseData.Message);
            if (typeof errorMessage === 'object') {
                errorText = (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.message) || (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.error);
            }
            else {
                errorText = errorMessage;
            }
        }
    }
    else if (error.request) {
        errorText =
            'Something has gone wrong during the request, view the console for more information';
    }
    else {
        errorText = error.message;
    }
    if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 401) {
        window.location.href = '/login';
        return Promise.reject(error);
    }
    if (((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 403) {
        window.location.href = '/forbidden';
        return Promise.reject(error);
    }
    notification.error({
        message: 'Something has gone wrong',
        description: errorText
    });
    return Promise.reject(error);
};
dataApi.interceptors.request.use(function (request) {
    var token = tokenManager.getToken();
    if (token) {
        request.headers.Authorization = "Bearer " + token;
    }
    return request;
}, function (error) { return error; });
dataApi.interceptors.response.use(function (response) { return response; }, function (error) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var originalRequest, headers, token, response, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    originalRequest = error.config;
                    if (!(error.response.status === 401 && !originalRequest._retry)) return [3 /*break*/, 4];
                    originalRequest._retry = true;
                    if (originalRequest.url === '/realisationverification/verify') {
                        return [2 /*return*/, dataApi(originalRequest)];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    headers = {
                        'Content-Type': 'application/json'
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    };
                    token = tokenManager.getToken();
                    if (token) {
                        headers.Authorization = "Bearer " + token;
                    }
                    return [4 /*yield*/, refreshApi.get('/auth/refresh', { withCredentials: true, headers: headers })];
                case 2:
                    response = _b.sent();
                    if (response.status !== 200) {
                        return [2 /*return*/, errorResponseInterceptor(error)];
                    }
                    tokenManager.setToken(response.data.accessToken, (_a = response.data.isAdmin) !== null && _a !== void 0 ? _a : false);
                    return [2 /*return*/, dataApi(originalRequest)];
                case 3:
                    err_1 = _b.sent();
                    // eslint-disable-next-line no-console
                    console.error(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, errorResponseInterceptor(error)];
            }
        });
    });
});
export { dataApi };
