var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ExportPdfStore } from './export-pdf.store';
import { useStore } from 'utilities';
import styles from './export-pdf.module.scss';
import { Button } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { useCallback, useEffect } from 'react';
var Component = function (_a) {
    var exportName = _a.exportName, tableData = _a.tableData, bigExpensesTableData = _a.bigExpensesTableData, applications = _a.applications, annualrealisation = _a.annualrealisation, pnoEntity = _a.pnoEntity;
    var _b = useStore(ExportPdfStore), exportPdf = _b.exportPdf, setApplications = _b.setApplications, setTableData = _b.setTableData, setBigExpensesTableData = _b.setBigExpensesTableData, setAnnualrealisation = _b.setAnnualrealisation, setPnoEntity = _b.setPnoEntity;
    useEffect(function () {
        setTableData(tableData);
        setBigExpensesTableData(bigExpensesTableData);
        setApplications(applications);
        setAnnualrealisation(annualrealisation);
        if (pnoEntity) {
            setPnoEntity(pnoEntity);
        }
    }, [setTableData, tableData, bigExpensesTableData, setApplications, applications, annualrealisation, pnoEntity]);
    var handleExportPdf = useCallback(function () {
        exportPdf(exportName);
    }, [exportPdf, exportName, tableData]);
    return (_jsx(Button, __assign({ onClick: handleExportPdf, className: styles['navigation-item'], type: "primary", shape: "round", icon: _jsx(FilePdfOutlined, {}, void 0) }, { children: "PDF" }), void 0));
};
export default Component;
