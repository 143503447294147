import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from 'utilities';
import { LoginStore } from './login.store';
var Component = function () {
    var store = useStore(LoginStore);
    var history = useHistory();
    useEffect(function () {
        store.setHistory(history).then();
    }, [store, history]);
    return null;
};
export default Component;
