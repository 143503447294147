var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BarChartPercentageSubmittedStore } from './bar-chart-percentage-submitted.store';
import { useStore } from 'utilities';
import styles from './bar-chart-percentage-submitted.module.scss';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React from 'react';
var Component = function () {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    var chartData = useStore(BarChartPercentageSubmittedStore).chartData;
    var data = {
        labels: chartData.map(function (row) { return row.team; }),
        datasets: [
            {
                data: chartData.map(function (row) { return row.percentage; }),
                backgroundColor: '#ccc8ad'
            }
        ]
    };
    var options = {
        responsive: true,
        Legend: false,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            }
        },
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 10
                }
            }
        }
    };
    return (_jsxs("div", __assign({ className: styles.root }, { children: [_jsx("div", __assign({ className: styles.title }, { children: "Percentage ingediende realisaties " + (new Date().getFullYear() - 1) }), void 0), _jsx(Bar, { data: data, options: options, width: '100', height: '100' }, void 0)] }), void 0));
};
export default Component;
