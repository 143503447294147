import moment from 'moment';
export var getPreviousYearsObjectList = function (numberOfYears) {
    var years = [];
    for (var index = numberOfYears - 1; index >= 0; index--) {
        years.push({ year: moment().year() - 1 - index, queryString: "\"annualrealisation\".\"year\" = " + (moment().year() - 1 - index) });
    }
    return years;
};
export var getPreviousYears = function (numberOfYears) {
    var years = [];
    for (var index = numberOfYears - 1; index >= 0; index--) {
        years.push(moment().year() - 1 - index);
    }
    return years;
};
