import { asGlobalStore } from 'utilities';
export var createStore = function () { return ({
    loading: false,
    processing: false,
    setLoading: function (state) {
        this.loading = state;
    },
    setProcessing: function (state) {
        this.processing = state;
    }
}); };
export default asGlobalStore(createStore());
