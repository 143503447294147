var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PieChartAnnualrealisationMethodStore } from './pie-chart-annualrealisation-methods.store';
import { useStore } from 'utilities';
import styles from './pie-chart-annualrealisation-methods.module.scss';
import { PieChart } from 'react-minimal-pie-chart';
import { Select } from 'antd';
var Component = function () {
    var store = useStore(PieChartAnnualrealisationMethodStore);
    var lineWidth = 60;
    var data = store.data.map(function (entry, i) {
        if (store.hovered === i) {
            return __assign(__assign({}, entry), { color: 'grey' });
        }
        return entry;
    });
    return (_jsxs("div", __assign({ className: styles.root }, { children: [_jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(Select, __assign({ mode: "multiple", allowClear: true, placeholder: "Selecteer een team...", defaultValue: store.selectedTeams, className: styles['table-picker'], onChange: function (arrayWithTeamNames) { return store.setSelectedTeams(arrayWithTeamNames); } }, { children: store.teamsFilters.map(function (filter) { return _jsx(Select.Option, __assign({ value: filter.queryString }, { children: filter.team.name }), filter.team.id); }) }), void 0) }), void 0), _jsx("div", __assign({ className: styles.title }, { children: "Overzicht statussen " + (new Date().getFullYear() - 1) }), void 0), _jsx(PieChart, { style: {
                    fontSize: '0.3rem',
                    height: '17rem'
                }, data: data, radius: PieChart.defaultProps.radius - 6, lineWidth: 60, animate: true, label: function (_a) {
                    var dataEntry = _a.dataEntry;
                    return dataEntry.title + ': ' + Math.round(dataEntry.percentage) + '%';
                }, labelPosition: 100 - lineWidth / 2, labelStyle: {
                    fill: '#fff',
                    opacity: 0.75,
                    pointerEvents: 'none'
                }, onMouseOver: function (_, index) {
                    store.setHovered(index);
                }, onMouseOut: function () {
                    store.setHovered(-1);
                } }, void 0), _jsxs("div", { children: ["1: Realisatie en BSN melden. #", store.data.length > 0 ? store.data[0].value : 0] }, void 0), _jsxs("div", { children: ["2: Realisatie melden. #", store.data.length > 0 ? store.data[1].value : 0] }, void 0), _jsxs("div", { children: ["3: BSN\u2019s melden. #", store.data.length > 0 ? store.data[2].value : 0] }, void 0), _jsxs("div", { children: ["4: Gereed. #", store.data.length > 0 ? store.data[3].value : 0] }, void 0)] }), void 0));
};
export default Component;
