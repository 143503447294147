var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { annualrealisationRepository } from 'annualrealisation';
import moment from 'moment';
import { teamRepository } from 'team';
import { escapeSingleQuotes } from 'utilities/formatters';
export var PieChartAnnualrealisationMethodStore = function () { return ({
    teams: [],
    setTeams: function (teams) {
        this.teams = teams;
        this.teamsFilters = teams.map(function (t) { return ({
            team: t,
            queryString: "annualrealisation.team = '" + escapeSingleQuotes(t.name) + "'"
        }); });
    },
    teamsTotalCount: 0,
    queryTeams: {
        page: 0,
        pageSize: 10,
        sort: 'annualrealisation.team',
        order: 'DESC',
        filter: ''
    },
    selectedTeams: [],
    teamsFilters: [],
    queryStringTeams: '',
    hovered: -1,
    setSelectedTeams: function (arrayValues) {
        return __awaiter(this, void 0, void 0, function () {
            var queryString, index, filter, totalCount, teamFilter, bothZero, onlyRealisationZero, onlyBsnZero, bothNotZero;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryString = '';
                        for (index = 0; index < arrayValues.length; index++) {
                            queryString =
                                index === 0
                                    ? "" + arrayValues[index]
                                    : queryString + " OR " + arrayValues[index];
                        }
                        this.queryStringTeams = queryString;
                        filter = '';
                        if (this.queryStringTeams.length > 0) {
                            filter = "(" + this.queryStringTeams + ")";
                        }
                        return [4 /*yield*/, annualrealisationRepository.getFilterCount({
                                filter: filter
                            })];
                    case 1:
                        totalCount = _a.sent();
                        this.teamsTotalCount = totalCount;
                        this.queryTeams.filter = filter;
                        this.queryTeams.page = 0;
                        teamFilter = filter.length > 0 ? " AND (" + filter + ")" : '';
                        return [4 /*yield*/, this.getAnnualrealisationsCount("annualrealisation.\"submittedRealisationMethod\" = 0 AND annualrealisation.\"submittedBSNMethod\" = 0 AND annualrealisation.year = " + (moment().year() - 1) + " " + teamFilter)];
                    case 2:
                        bothZero = _a.sent();
                        return [4 /*yield*/, this.getAnnualrealisationsCount("annualrealisation.\"submittedRealisationMethod\" = 0 AND NOT annualrealisation.\"submittedBSNMethod\" = 0 AND annualrealisation.year = " + (moment().year() - 1) + " " + teamFilter)];
                    case 3:
                        onlyRealisationZero = _a.sent();
                        return [4 /*yield*/, this.getAnnualrealisationsCount("NOT annualrealisation.\"submittedRealisationMethod\" = 0 AND annualrealisation.\"submittedBSNMethod\" = 0 AND annualrealisation.year = " + (moment().year() - 1) + " " + teamFilter)];
                    case 4:
                        onlyBsnZero = _a.sent();
                        return [4 /*yield*/, this.getAnnualrealisationsCount("NOT annualrealisation.\"submittedRealisationMethod\" = 0 AND NOT annualrealisation.\"submittedBSNMethod\" = 0 AND annualrealisation.year = " + (moment().year() - 1) + " " + teamFilter)];
                    case 5:
                        bothNotZero = _a.sent();
                        this.setData([{ title: '1', value: bothZero, color: '#E38627' },
                            { title: '2', value: onlyRealisationZero, color: '#C13C37' },
                            { title: '3', value: onlyBsnZero, color: '#6A2135' },
                            { title: '4', value: bothNotZero, color: '#6A2896' }]);
                        return [2 /*return*/];
                }
            });
        });
    },
    setHovered: function (hovered) {
        this.hovered = hovered;
    },
    data: [],
    setData: function (data) {
        this.data = data;
    },
    getAnnualrealisationsCount: function (filter) {
        return __awaiter(this, void 0, void 0, function () {
            var annualrealisation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, annualrealisationRepository.getCount(({ filter: filter }))];
                    case 1:
                        annualrealisation = _a.sent();
                        return [2 /*return*/, annualrealisation];
                }
            });
        });
    },
    onInitialize: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setSelectedTeams([]);
                        _a = this.setTeams;
                        return [4 /*yield*/, teamRepository.getAll()];
                    case 1:
                        _a.apply(this, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
}); };
