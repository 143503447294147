var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import jsPDF from 'jspdf';
import autoTableHtmlToJson from 'jspdf-autotable';
import { TableType } from 'organisations/annualrealisation-input-sheet/annualrealisation-input-sheet';
import { readProp } from 'utilities/helpers/helper';
import { dateFormatterMonthYear } from 'utilities/formatters/date-formatters';
import { annualrealisationRepository } from 'annualrealisation';
import { RealisationStore } from '../realisation.store';
export var ExportPdfStore = function () { return ({
    applications: [],
    annualrealisation: {},
    pnoEntity: {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData: [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bigExpensesTableData: [],
    dataKeys: [
        {
            type: TableType.Hours,
            title: 'Uren',
            rowsTitle: 'Projecten',
            grantedProp: 'grantedHours',
            realisedProp: 'realisedHours',
            reportedProp: 'reportedHours',
            totalGrantedProp: 'totalCountGrantedHours',
            totalRealisedProp: 'totalCountRealisedHours',
            totalReportedProp: 'totalCountReportedHours',
            entitledProp: 'entitledHours'
        },
        {
            type: TableType.Costs,
            title: 'Kosten',
            rowsTitle: 'Projecten',
            grantedProp: 'grantedCosts',
            realisedProp: 'realisedCosts',
            reportedProp: 'reportedCosts',
            totalGrantedProp: 'totalCountGrantedCosts',
            totalRealisedProp: 'totalCountRealisedCosts',
            totalReportedProp: 'totalCountReportedCosts',
            entitledProp: 'entitledCosts',
            hideSummary: true
        },
        {
            type: TableType.Expenses,
            title: 'Uitgaven',
            rowsTitle: 'Projecten',
            grantedProp: 'grantedExpenses',
            realisedProp: 'realisedExpenses',
            reportedProp: 'reportedExpenses',
            totalGrantedProp: 'totalCountGrantedExpenses',
            totalRealisedProp: 'totalCountRealisedExpenses',
            totalReportedProp: 'totalCountReportedExpenses',
            entitledProp: 'entitledExpenses',
            hideSummary: true
        },
        {
            type: TableType.BigExpenses,
            title: 'Uitgaven > 1 mln.',
            rowsTitle: '',
            grantedProp: 'grantedBigExpenses',
            realisedProp: 'realisedBigExpenses',
            reportedProp: 'reportedBigExpenses',
            totalGrantedProp: 'totalCountGrantedBigExpenses',
            totalRealisedProp: 'totalCountRealisedBigExpenses',
            totalReportedProp: 'totalCountReportedBigExpenses',
            entitledProp: 'totalCountRealisedExpenses',
            hideSummary: true
        },
        {
            type: TableType.CostsExpenses,
            title: 'Totaal Kosten en Uitgaven',
            grantedProp: 'grantedCostsExpenses',
            realisedProp: 'realisedCostsExpenses',
            reportedProp: 'reportedCostsExpenses',
            totalGrantedProp: 'totalCountGrantedCostsExpenses',
            totalRealisedProp: 'totalCountRealisedCostsExpenses',
            totalReportedProp: 'totalCountReportedCostsExpenses',
            entitledProp: 'entitledCostsExpenses',
            hideBody: true
        }
    ],
    applicationHeadingColors: [
        '#E9E9E9',
        '#C8C8C8',
        '#B2B2B2',
        '#9C9C9C'
    ],
    setApplications: function (applications) {
        this.applications = applications;
    },
    setAnnualrealisation: function (annualrealisation) {
        this.annualrealisation = annualrealisation;
    },
    setPnoEntity: function (entity) {
        this.pnoEntity = entity;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setTableData: function (tabledata) {
        this.tableData = tabledata;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setBigExpensesTableData: function (tabledata) {
        this.bigExpensesTableData = tabledata;
    },
    exportPdf: function (customerName) {
        var _this = this;
        var unit = 'px';
        var size = 'A4';
        var orientation = 'landscape';
        var marginLeft = 30;
        // eslint-disable-next-line new-cap
        var doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        var title = "Export voor " + customerName;
        doc.text(title, marginLeft, 40);
        if (this.pnoEntity.logo) {
            var imgData = "data:image/png;base64," + this.pnoEntity.logo;
            var imgProps = doc.getImageProperties(imgData);
            var width = doc.internal.pageSize.getWidth();
            var originalImageW = imgProps.width;
            var originalImageH = imgProps.height;
            var aspectRatio = originalImageW / originalImageH;
            var imageW = aspectRatio * 40;
            var imageH = 40;
            doc.addImage(imgData, 'PNG', width - (imageW + marginLeft), 30, imageW, imageH);
        }
        var dataKeys = this.annualrealisation.forfait ? this.dataKeys.filter(function (dataKey) { return dataKey.type === TableType.Hours; }) : this.dataKeys;
        dataKeys.map(function (dataKey) {
            if (dataKey.type === TableType.BigExpenses && !_this.annualrealisation.isBigExpensesAmountEnabled) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var finalY = doc.lastAutoTable.finalY !== undefined ? doc.lastAutoTable.finalY : 50;
            doc.setFontSize(10);
            doc.text(dataKey.title, marginLeft, finalY + 20);
            autoTableHtmlToJson(doc, ({
                startY: finalY + 25,
                margin: { top: 65 },
                styles: { fontSize: 7 },
                theme: 'grid',
                headStyles: { fillColor: '#ccc8ad', textColor: 20, halign: 'center', lineWidth: 0.5 },
                footStyles: { fillColor: '#fff', textColor: 20, halign: 'right', fontStyle: 'normal', lineWidth: 0.5 },
                bodyStyles: { halign: 'right', lineWidth: 0.5 },
                columnStyles: _this.generateBodyCellStyles(),
                head: _this.generateHead(dataKey),
                body: _this.generateBody(dataKey),
                foot: _this.generateSummary(dataKey)
            }));
        });
        doc.save("Report_" + customerName + "_" + this.annualrealisation.year + ".pdf");
        this.setBlockInputRealisations();
    },
    setBlockInputRealisations: function () {
        return __awaiter(this, void 0, void 0, function () {
            var annualrealisation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        annualrealisation = this.annualrealisation;
                        if (!annualrealisation) {
                            return [2 /*return*/];
                        }
                        RealisationStore.setAnnualrealisation(__assign(__assign({}, annualrealisation), { blockInputRealisation: true }));
                        return [4 /*yield*/, annualrealisationRepository.patch(this.annualrealisation.id, { blockInputRealisation: true })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    generateBody: function (dataKey) {
        var _this = this;
        if (dataKey.hideBody) {
            return undefined;
        }
        if (dataKey.type === TableType.BigExpenses) {
            return this.bigExpensesTableData.map(function (row) { return __spreadArray(__spreadArray([
                {
                    content: 'Uitgaven > 1 mln.',
                    styles: { halign: 'left' }
                }
            ], _this.applications.flatMap(function (application) { return [
                readProp(row, dataKey.grantedProp + ";" + application.id).toLocaleString(undefined, { minimumFractionDigits: 0 }),
                readProp(row, dataKey.realisedProp + ";" + application.id).toLocaleString(undefined, { minimumFractionDigits: 0 })
            ]; }), true), [
                row[dataKey.totalGrantedProp].toLocaleString(undefined, { minimumFractionDigits: 0 }),
                row[dataKey.totalRealisedProp].toLocaleString(undefined, { minimumFractionDigits: 0 }),
                row[dataKey.totalReportedProp].toLocaleString(undefined, { minimumFractionDigits: 0 })
            ], false); });
        }
        return this.tableData.map(function (row) { return __spreadArray(__spreadArray([
            {
                content: row.mark + " - " + row.name,
                styles: { halign: 'left' }
            }
        ], _this.applications.flatMap(function (application) { return [
            readProp(row, dataKey.grantedProp + ";" + application.id).toLocaleString(undefined, { minimumFractionDigits: 0 }),
            readProp(row, dataKey.realisedProp + ";" + application.id).toLocaleString(undefined, { minimumFractionDigits: 0 })
        ]; }), true), [
            row[dataKey.totalGrantedProp].toLocaleString(undefined, { minimumFractionDigits: 0 }),
            row[dataKey.totalRealisedProp].toLocaleString(undefined, { minimumFractionDigits: 0 }),
            row[dataKey.totalReportedProp].toLocaleString(undefined, { minimumFractionDigits: 0 })
        ], false); });
    },
    generateBodyCellStyles: function () {
        var _this = this;
        var columnStyles = {
            1: { halign: 'left', fillColor: '#fff' }
        };
        this.applications.forEach(function (_, index) {
            columnStyles[(index * 2) + 1] = { fillColor: _this.applicationHeadingColors[index] };
            columnStyles[(index * 2) + 2] = { fillColor: _this.applicationHeadingColors[index] };
        });
        columnStyles[(this.applications.length * 2) + 1] = { fillColor: '#ccc8ad' };
        columnStyles[(this.applications.length * 2) + 2] = { fillColor: '#ccc8ad' };
        columnStyles[(this.applications.length * 2) + 3] = { fillColor: '#ccc8ad' };
        return columnStyles;
    },
    generateHead: function (dataKey) {
        var _this = this;
        var heading = [
            __spreadArray(__spreadArray([
                {
                    content: dataKey.rowsTitle || '',
                    styles: { halign: 'left', valign: 'bottom', fillColor: '#fff' },
                    rowSpan: 2
                }
            ], this.applications.map(function (application, index) { return ({
                content: dateFormatterMonthYear(application.StartDate) + '\n' + application.SONumber,
                colSpan: 2,
                styles: { fillColor: _this.applicationHeadingColors[index] }
            }); }), true), [
                {
                    content: 'Totalen',
                    colSpan: 3,
                    styles: { fillColor: '#ccc8ad' }
                }
            ], false),
            __spreadArray(__spreadArray([], this.applications.flatMap(function (_, index) { return [
                {
                    content: 'Toegekend',
                    styles: { fillColor: _this.applicationHeadingColors[index] }
                },
                {
                    content: 'Gerealiseerd',
                    styles: { fillColor: _this.applicationHeadingColors[index] }
                }
            ]; }), true), [
                {
                    content: 'Toegekend',
                    styles: { fillColor: '#ccc8ad' }
                },
                {
                    content: 'Gerealiseerd',
                    styles: { fillColor: '#ccc8ad' }
                },
                {
                    content: 'Mededeling',
                    styles: { fillColor: '#ccc8ad' }
                }
            ], false)
        ];
        return heading;
    },
    generateSummary: function (dataKey) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (dataKey.hideSummary) {
            return undefined;
        }
        var summary = __spreadArray(__spreadArray([
            {
                content: 'Totaal',
                styles: { halign: 'left' }
            }
        ], this.applications.flatMap(function (application, index) { return [
            {
                content: readProp(application, dataKey.grantedProp).toLocaleString(undefined, { minimumFractionDigits: 0 }),
                styles: { fillColor: _this.applicationHeadingColors[index] }
            },
            {
                content: readProp(application, dataKey.realisedProp).toLocaleString(undefined, { minimumFractionDigits: 0 }),
                styles: { fillColor: _this.applicationHeadingColors[index] }
            }
        ]; }), true), [
            {
                content: (_a = this.annualrealisation[dataKey.grantedProp]) === null || _a === void 0 ? void 0 : _a.toLocaleString(undefined, { minimumFractionDigits: 0 }),
                styles: { fillColor: '#ccc8ad' }
            },
            {
                content: (_b = this.annualrealisation[dataKey.realisedProp]) === null || _b === void 0 ? void 0 : _b.toLocaleString(undefined, { minimumFractionDigits: 0 }),
                styles: { fillColor: '#ccc8ad' }
            },
            {
                content: (_c = this.annualrealisation[dataKey.reportedProp]) === null || _c === void 0 ? void 0 : _c.toLocaleString(undefined, { minimumFractionDigits: 0 }),
                styles: { fillColor: '#959072' }
            }
        ], false);
        var entitlement = [
            {
                content: 'Uiteindelijk recht',
                colSpan: (this.applications.length * 2) + 3,
                styles: { halign: 'left' }
            },
            {
                content: (_d = this.annualrealisation[dataKey.entitledProp]) === null || _d === void 0 ? void 0 : _d.toLocaleString(undefined, { minimumFractionDigits: 0 })
            }
        ];
        if (dataKey.type === TableType.CostsExpenses) {
            var costsDataKey = this.dataKeys.find(function (_a) {
                var type = _a.type;
                return type === TableType.Costs;
            });
            var bigExpensesDataKey = this.dataKeys.find(function (_a) {
                var type = _a.type;
                return type === TableType.BigExpenses;
            });
            var calculatedReportedExpenses = ((_e = this.annualrealisation.reportedBigExpenses) !== null && _e !== void 0 ? _e : 0) + ((_f = this.annualrealisation.reportedExpenses) !== null && _f !== void 0 ? _f : 0);
            var reportedCosts = [
                {
                    content: 'Mededeling (Kosten)',
                    colSpan: (this.applications.length * 2) + 3,
                    styles: { halign: 'left' }
                },
                {
                    content: (_g = this.annualrealisation[costsDataKey.reportedProp]) === null || _g === void 0 ? void 0 : _g.toLocaleString(undefined, { minimumFractionDigits: 0 }),
                    styles: { fillColor: '#959072' }
                }
            ];
            var reportedExpenses = [
                {
                    content: 'Mededeling (Uitgaven)',
                    colSpan: (this.applications.length * 2) + 3,
                    styles: { halign: 'left' }
                },
                {
                    content: calculatedReportedExpenses.toLocaleString(undefined, { minimumFractionDigits: 0 }),
                    styles: { fillColor: '#959072' }
                }
            ];
            var reportedBigExpenses = [
                {
                    content: 'Uitgaven > 1 mln.',
                    colSpan: (this.applications.length * 2) + 3,
                    styles: { halign: 'left' }
                },
                {
                    content: (_h = this.annualrealisation[bigExpensesDataKey.reportedProp]) === null || _h === void 0 ? void 0 : _h.toLocaleString(undefined, { minimumFractionDigits: 0 }),
                    styles: { fillColor: '#959072' }
                }
            ];
            if (this.annualrealisation.isBigExpensesAmountEnabled) {
                return [summary, reportedCosts, reportedExpenses, reportedBigExpenses, entitlement];
            }
            return [summary, reportedCosts, reportedExpenses, entitlement];
        }
        else {
            return [summary, entitlement];
        }
    }
}); };
