var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddPeriodeStore } from './add-periode.store';
import { useStore } from 'utilities';
import styles from './add-periode.module.scss';
import { Button, Row, Col, DatePicker, Input, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { Formik, Form } from 'formik';
import moment from 'moment';
var Component = function (props) {
    var periodeModal = props.periodeModal, onCancel = props.onCancel, addApplication = props.addApplication, applications = props.applications;
    var _a = useStore(AddPeriodeStore), getDisabledDate = _a.getDisabledDate, getValidationSchema = _a.getValidationSchema;
    return (_jsx("div", __assign({ className: styles.root }, { children: _jsx(Modal, __assign({ className: styles.root, width: 600, centered: true, visible: periodeModal, title: applications.length >= 4 ? 'Nieuwe periode toevoegen niet mogelijk' : 'Nieuwe periode toevoegen', onCancel: onCancel, footer: null }, { children: applications.length >= 4 ? _jsx("div", { children: "Voor dit jaar heb je het maximum van 4 periodes al bereikt." }, void 0) : (_jsx(Formik, __assign({ validateOnChange: true, initialValues: { start: undefined, end: moment(props.year + "-12-01"), sonumber: undefined }, validationSchema: getValidationSchema, onSubmit: function (data, _a) {
                    var setSubmitting = _a.setSubmitting, resetForm = _a.resetForm;
                    return __awaiter(void 0, void 0, void 0, function () {
                        var success;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    setSubmitting(true);
                                    if (!getValidationSchema().isValidSync(data)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, addApplication(data)];
                                case 1:
                                    success = _b.sent();
                                    if (success) {
                                        resetForm();
                                    }
                                    _b.label = 2;
                                case 2:
                                    setSubmitting(false);
                                    return [2 /*return*/];
                            }
                        });
                    });
                } }, { children: function (_a) {
                    var values = _a.values, isSubmitting = _a.isSubmitting, setFieldValue = _a.setFieldValue, handleSubmit = _a.handleSubmit, errors = _a.errors, isValid = _a.isValid;
                    return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsxs(Row, { children: [_jsx(Col, { children: _jsx(FormItem, __assign({ label: "Startdatum", labelCol: { span: 24 }, help: errors.start }, { children: _jsx(DatePicker, { name: "start", placeholder: "Startdatum", picker: "month", disabledDate: function (x) { return getDisabledDate(Number(props.year), x); }, defaultPickerValue: moment(props.year + "-1-1"), value: values.start, onChange: function (val) {
                                                    setFieldValue('start', val);
                                                } }, void 0) }), void 0) }, void 0), _jsx(Col, { children: _jsx(FormItem, __assign({ label: "Einddatum", labelCol: { span: 24 }, help: errors.end }, { children: values.end ? moment(values.end).format('YYYY-MM') : '' }), void 0) }, void 0)] }, void 0), _jsx(FormItem, __assign({ label: "SO-referentienummer", labelCol: { span: 24 }, help: errors.sonumber }, { children: _jsx(Input, { name: "sonumber", maxLength: 6, placeholder: "SO-referentienummer (6 eindcijfers)", addonBefore: "SO" + props.year.toString().substring(2, 4), value: values.sonumber, onChange: function (val) {
                                        setFieldValue('sonumber', val.target.value);
                                    } }, void 0) }), void 0), _jsx(Button, __assign({ className: styles['submit-button'], disabled: !isValid, type: "primary", htmlType: "submit" }, { children: "Periode Toevoegen" }), void 0), _jsx(Button, __assign({ disabled: isSubmitting, htmlType: "button", onClick: onCancel }, { children: "Annuleren" }), void 0)] }), void 0));
                } }), void 0)) }), void 0) }), void 0));
};
export default Component;
