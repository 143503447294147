var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styles from './pno-entity-management.module.scss';
import FormItem from 'antd/lib/form/FormItem';
import { Badge, Button, Image, Input, Select, Upload } from 'antd';
import { Form, Formik } from 'formik';
import { loaderStore } from '../../shared/loader';
import { CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { PnoEntityManagementStore } from './pno-entity-management-store';
import * as yup from 'yup';
import { useStore } from '../../utilities';
export var Component = function (props) {
    var _a = props;
    var store = useStore(PnoEntityManagementStore);
    var _b = useState([]), pnoEntities = _b[0], setPnoEntities = _b[1];
    var _c = useState(''), pnoEntityName = _c[0], setPnoEntityName = _c[1];
    var _d = useState(''), pnoEntityLogo = _d[0], setPnoEntityLogo = _d[1];
    useEffect(function () {
        setPnoEntityName(store.pnoEntity.name);
        setPnoEntityLogo(store.pnoEntity.logo);
    }, [store.pnoEntity, store.succesfullySavedStatus]);
    useEffect(function () {
        setPnoEntities(store.pnoEntities);
    }, [store.pnoEntities]);
    var validationSchema = yup.object({}).shape({
        name: yup.string().trim().required('Dit veld is verplicht'),
        logo: yup.string().trim().required('Dit veld is verplicht')
    });
    return (_jsx("div", __assign({ className: styles['table-container'] }, { children: _jsxs("div", __assign({ className: styles['table-search-container'] }, { children: [_jsx(Button, __assign({ type: 'primary', onClick: function () {
                        store.setNewPnoEntityMode(!store.isNew);
                    } }, { children: store.isNew ? 'Bestaande pno-entiteit aanpassen' : 'Nieuwe pno-entiteit aanmaken' }), void 0), _jsx(Formik, __assign({ onSubmit: function (data, _a) {
                        var setSubmitting = _a.setSubmitting;
                        return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_b) {
                                setSubmitting(true);
                                store.addPnoEntity(data);
                                setSubmitting(false);
                                return [2 /*return*/];
                            });
                        });
                    }, validationSchema: validationSchema, initialValues: {
                        name: store.pnoEntity.name, logo: store.pnoEntity.logo
                    } }, { children: function (_a) {
                        var handleSubmit = _a.handleSubmit, setFieldValue = _a.setFieldValue, isSubmitting = _a.isSubmitting, errors = _a.errors;
                        return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [!store.isNew && (_jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(FormItem, __assign({ label: 'Selecteer pno-entiteit', labelCol: { span: 24 } }, { children: _jsx(Select, __assign({ allowClear: true, placeholder: 'Selecteer een pno-entiteit...', defaultValue: pnoEntityName !== '' ? pnoEntityName : undefined, className: styles['table-picker'], onChange: function (id) { return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, store.setCurrentPnoEntity(id)];
                                                        case 1:
                                                            _a.sent();
                                                            setFieldValue('name', store.pnoEntity.name);
                                                            setFieldValue('logo', store.pnoEntity.logo);
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); } }, { children: pnoEntities.map(function (option) { return _jsx(Select.Option, __assign({ value: option.id }, { children: option.name }), option.id); }) }), void 0) }), void 0) }), void 0)), (store.pnoEntity.id || store.isNew) && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(FormItem, __assign({ label: "Naam", labelCol: { span: 24 }, help: errors.name }, { children: _jsx(Input, { allowClear: true, placeholder: "Naam...", className: styles['table-picker'], value: pnoEntityName, onChange: function (event) {
                                                        setPnoEntityName(event.target.value);
                                                        setFieldValue('name', event.target.value);
                                                    } }, void 0) }), void 0) }), void 0), _jsxs(FormItem, __assign({ label: 'Logo', labelCol: { span: 24 }, help: errors.logo }, { children: [pnoEntityLogo && (_jsx("div", __assign({ className: styles['logo-wrapper'] }, { children: _jsx(Badge, __assign({ count: _jsx(Button, { size: 'small', type: 'primary', shape: 'circle', icon: _jsx(CloseOutlined, {}, void 0), onClick: function () {
                                                                setPnoEntityLogo('');
                                                                setFieldValue('logo', '');
                                                            } }, void 0) }, { children: _jsx(Image, { src: "data:image/png;base64," + pnoEntityLogo, width: '100px' }, void 0) }), void 0) }), void 0)), _jsx(Upload, __assign({ beforeUpload: function (file) { return __awaiter(void 0, void 0, void 0, function () {
                                                        var fileReader;
                                                        return __generator(this, function (_a) {
                                                            fileReader = new FileReader();
                                                            fileReader.readAsDataURL(file);
                                                            fileReader.onload = function () {
                                                                var result = fileReader.result;
                                                                var base64 = result.split(',')[1];
                                                                setPnoEntityLogo(base64);
                                                                setFieldValue('logo', base64);
                                                            };
                                                            return [2 /*return*/, false];
                                                        });
                                                    }); }, maxCount: 1, showUploadList: false }, { children: _jsx(Button, __assign({ icon: _jsx(UploadOutlined, {}, void 0) }, { children: "Upload logo" }), void 0) }), void 0)] }), void 0), _jsxs(Button, __assign({ className: styles['submit-button'], disabled: isSubmitting || loaderStore.processing, type: "primary", htmlType: "submit" }, { children: [!loaderStore.processing ? _jsx("span", {}, void 0) : (_jsx(LoadingOutlined, {}, void 0)), " Opslaan"] }), void 0)] }, void 0)), !store.succesfullySavedStatus ? _jsx("div", {}, void 0) : (_jsx("span", { children: "De wijzigingen zijn opgeslagen!" }, void 0))] }), void 0));
                    } }), void 0)] }), void 0) }), void 0));
};
