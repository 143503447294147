import { jsx as _jsx } from "react/jsx-runtime";
import { environment } from 'utilities/environment';
import authStore from './auth.store';
import { useLocation, Redirect } from 'react-router-dom';
export function RequireAuth(_a) {
    var children = _a.children;
    var location = useLocation();
    localStorage.setItem('returnUrl', location.pathname + location.search);
    if (environment.production && !authStore.isSignedIn) {
        return (_jsx(Redirect, { to: {
                pathname: '/login',
                state: {
                    from: location
                }
            } }, void 0));
    }
    return children;
}
