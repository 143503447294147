var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnnualrealisationStatusStore } from './edit-status.store';
import { useStore } from 'utilities';
import styles from './edit-status.module.scss';
import { Form, Formik } from 'formik';
import FormItem from 'antd/lib/form/FormItem';
import * as yup from 'yup';
import { loaderStore } from 'shared/loader';
import { Button, Radio, DatePicker, AutoComplete, Input } from 'antd';
import { SubmittedMethod } from '@application/domain';
import { LoadingOutlined } from '@ant-design/icons';
var Component = function (props) {
    var annualrealisation = props.annualrealisation, editStatus = props.editStatus, succesfullySavedStatus = props.succesfullySavedStatus;
    var validationSchema = yup.object({}).shape({
        submittedBSNBy: yup.string()
            .nullable(true).trim().max(50, 'De naam van de consultant is te lang').min(3, 'De naam van de consultant is te kort')
            .when('submittedBSNMethod', {
            is: function (submittedBSNMethod) { return submittedBSNMethod === SubmittedMethod.Consultant; },
            then: yup.string().required('Dit veld is verplicht').test('Test naam medewerker', 'Medewerker moet er een uit de lijst zijn', function (val) { return props.employees.findIndex(function (x) { return x.name === (val === null || val === void 0 ? void 0 : val.toString()); }) >= 0; })
        }),
        submittedRealisationBy: yup.string()
            .nullable(true).trim().max(50, 'De naam van de consultant is te lang').min(3, 'De naam van de consultant is te kort')
            .when('submittedRealisationMethod', {
            is: function (submittedRealisationMethod) { return submittedRealisationMethod === SubmittedMethod.Consultant; },
            then: yup.string().required('Dit veld is verplicht').test('Test naam medewerker', 'Medewerker moet er een uit de lijst zijn', function (val) { return props.employees.findIndex(function (x) { return x.name === (val === null || val === void 0 ? void 0 : val.toString()); }) >= 0; })
        })
    });
    if (!(annualrealisation === null || annualrealisation === void 0 ? void 0 : annualrealisation.id) || loaderStore.loading === true) {
        return _jsx(_Fragment, {}, void 0);
    }
    var _a = useStore(AnnualrealisationStatusStore), getMethodOfSubmission = _a.getMethodOfSubmission, getMomentDate = _a.getMomentDate, transformToDate = _a.transformToDate, showConsultantInputRealisation = _a.showConsultantInputRealisation, showDateInputRealisation = _a.showDateInputRealisation, showConsultantInputBSN = _a.showConsultantInputBSN, showDateInputBSN = _a.showDateInputBSN;
    return (_jsx("div", __assign({ className: styles.root }, { children: _jsx(Formik, __assign({ validateOnChange: true, initialValues: { id: annualrealisation.id, organisationDataId: annualrealisation.organisationDataId,
                year: annualrealisation.year, forfait: annualrealisation.forfait,
                submittedBSNMethod: annualrealisation.submittedBSNMethod,
                submittedBSNBy: annualrealisation.submittedBSNBy === null ? '' : annualrealisation.submittedBSNBy,
                submittedBSNAt: annualrealisation.submittedBSNAt, submittedRealisationMethod: annualrealisation.submittedRealisationMethod,
                submittedRealisationBy: annualrealisation.submittedRealisationBy === null ? '' : annualrealisation.submittedRealisationBy,
                submittedRealisationAt: annualrealisation.submittedRealisationAt,
                isArchived: false,
                blockInputRealisation: annualrealisation.blockInputRealisation,
                bsnComment: annualrealisation.bsnComment
            }, validationSchema: validationSchema, onSubmit: function (data, _a) {
                var setSubmitting = _a.setSubmitting;
                return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                setSubmitting(true);
                                return [4 /*yield*/, editStatus(data)];
                            case 1:
                                _b.sent();
                                setSubmitting(false);
                                return [2 /*return*/];
                        }
                    });
                });
            } }, { children: function (_a) {
                var values = _a.values, isSubmitting = _a.isSubmitting, setFieldValue = _a.setFieldValue, handleSubmit = _a.handleSubmit, errors = _a.errors;
                return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", __assign({ className: styles['form-container'] }, { children: [_jsxs("div", __assign({ className: styles['form-column'] }, { children: [_jsx(FormItem, __assign({ label: "Realisatie mededeling gedaan door:", labelCol: { span: 24 } }, { children: _jsxs(Radio.Group, __assign({ defaultValue: getMethodOfSubmission(values, 'realisation'), buttonStyle: "solid", onChange: function (val) {
                                                    setFieldValue('submittedRealisationBy', '');
                                                    setFieldValue('submittedRealisationMethod', Number(val.target.value));
                                                } }, { children: [_jsx(Radio.Button, __assign({ value: SubmittedMethod.None }, { children: "Niemand" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.Consultant }, { children: "Consultant" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.Customer }, { children: "Klant" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.Bankrupt }, { children: "Failliet" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.NoneIndependentGreaterThan500 }, { children: "Niemand, zelfstandige > 500 uur" }), void 0)] }), void 0) }), void 0), !showConsultantInputRealisation ? _jsx("div", {}, void 0) : (_jsx("span", { children: _jsx(FormItem, __assign({ label: "Naam mededeler realisatie", labelCol: { span: 24 }, help: errors.submittedRealisationBy }, { children: _jsx(AutoComplete, { options: props.employees.map(function (employee) { return ({
                                                        value: employee.name,
                                                        label: employee.name
                                                    }); }), value: values.submittedRealisationBy, onChange: function (v) { return setFieldValue('submittedRealisationBy', v); }, placeholder: "Naam mededeler realisatie", filterOption: function (inputValue, option) {
                                                        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                    } }, void 0) }), void 0) }, void 0)), !showDateInputRealisation ? _jsx("div", {}, void 0) : (_jsx("span", { children: _jsx(FormItem, __assign({ label: "Datum mededeling realisatie", labelCol: { span: 24 }, help: errors.submittedRealisationAt }, { children: _jsx(DatePicker, { name: "start", placeholder: "Indiendatum", picker: "date", defaultPickerValue: undefined, value: getMomentDate(values.submittedRealisationAt), onChange: function (val) {
                                                        setFieldValue('submittedRealisationAt', transformToDate(val));
                                                    } }, void 0) }), void 0) }, void 0))] }), void 0), _jsxs("div", __assign({ className: styles['form-column'] }, { children: [_jsxs("div", __assign({ className: styles['bsn-container'] }, { children: [_jsx(FormItem, __assign({ label: "BSN Mededeling gedaan door:", labelCol: { span: 24 } }, { children: _jsxs(Radio.Group, __assign({ defaultValue: getMethodOfSubmission(values, 'bsn'), buttonStyle: "solid", onChange: function (val) {
                                                            setFieldValue('submittedBSNBy', '');
                                                            setFieldValue('submittedBSNMethod', Number(val.target.value));
                                                        } }, { children: [_jsx(Radio.Button, __assign({ value: SubmittedMethod.None }, { children: "Niemand" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.Consultant }, { children: "Consultant" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.Customer }, { children: "Klant" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.Bankrupt }, { children: "Failliet / N.v.t" }), void 0), _jsx(Radio.Button, __assign({ value: SubmittedMethod.NotNecessary }, { children: "Niet nodig, geen uren gerealiseerd" }), void 0)] }), void 0) }), void 0), _jsx(FormItem, __assign({ label: "Opmerking", labelCol: { span: 24 } }, { children: _jsx(Input, { allowClear: true, placeholder: "Opmerking", defaultValue: values.bsnComment, onChange: function (event) { return setFieldValue('bsnComment', event.target.value); } }, void 0) }), void 0)] }), void 0), !showConsultantInputBSN ? _jsx("div", {}, void 0) : (_jsx("span", { children: _jsx(FormItem, __assign({ label: "Naam mededeler BSN", labelCol: { span: 24 }, help: errors.submittedBSNBy }, { children: _jsx(AutoComplete, { options: props.employees.map(function (employee) { return ({
                                                        value: employee.name,
                                                        label: employee.name
                                                    }); }), defaultValue: values.submittedBSNBy, onChange: function (v) { return setFieldValue('submittedBSNBy', v); }, placeholder: "Naam mededeler BSN", filterOption: function (inputValue, option) {
                                                        return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                    } }, void 0) }), void 0) }, void 0)), !showDateInputBSN ? _jsx("div", {}, void 0) : (_jsx("span", { children: _jsx(FormItem, __assign({ label: "Datum mededeling BSN", labelCol: { span: 24 }, help: errors.submittedBSNAt }, { children: _jsx(DatePicker, { name: "start", placeholder: "Indiendatum", picker: "date", defaultPickerValue: undefined, value: getMomentDate(values.submittedBSNAt), onChange: function (val) {
                                                        setFieldValue('submittedBSNAt', transformToDate(val));
                                                    } }, void 0) }), void 0) }, void 0))] }), void 0)] }), void 0), _jsxs(Button, __assign({ className: styles['submit-button'], disabled: isSubmitting || loaderStore.processing, type: "primary", htmlType: "submit" }, { children: [!loaderStore.processing ? _jsx("span", {}, void 0) : (_jsx(LoadingOutlined, {}, void 0)), " Status Opslaan"] }), void 0), !succesfullySavedStatus ? _jsx("div", {}, void 0) : (_jsx("span", { children: "De wijzigingen zijn opgeslagen!" }, void 0))] }), void 0));
            } }), void 0) }), void 0));
};
export default Component;
