var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { v4 } from 'uuid';
import * as yup from 'yup';
import { loaderStore } from '../../shared/loader';
import { keyValueRepository, keyValueTypeRepository } from 'key-value';
import { notification } from 'antd';
import { useState } from 'react';
export var validationSchema = yup.object({}).shape({
    id: yup.string(),
    name: yup.string().trim().required('Dit veld is verplicht'),
    keyValueTypeDataId: yup.string(),
    isArchived: yup.boolean().optional()
});
export var UseKeyValueManagement = function () {
    var _a = useState(undefined), keyValueType = _a[0], setKeyValueType = _a[1];
    var _b = useState(), keyValues = _b[0], setKeyValues = _b[1];
    var _c = useState(), keyValueTypes = _c[0], setKeyValueTypes = _c[1];
    var _d = useState({ id: '', name: '', isArchived: false, keyValueTypeDataId: '' }), keyValue = _d[0], setKeyValue = _d[1];
    var setProcessing = function (processing) {
        loaderStore.setProcessing(processing);
    };
    var addKeyValue = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var isNew, newKeyValue;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setProcessing(true);
                    isNew = ((_a = data.id) === null || _a === void 0 ? void 0 : _a.length) === 0;
                    newKeyValue = {
                        id: isNew ? v4() : data.id,
                        name: data.name,
                        keyValueTypeDataId: data.keyValueTypeDataId,
                        isArchived: data.isArchived
                    };
                    if (!isNew) return [3 /*break*/, 2];
                    return [4 /*yield*/, keyValueRepository.insert(newKeyValue)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, keyValueRepository.update(newKeyValue)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    setProcessing(false);
                    notification.info({
                        message: 'Wijziging opgeslagen',
                        duration: 1
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var getKeyValueTypes = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, keyValueTypeRepository.get()];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var getKeyValues = function (id) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, keyValueRepository.get({ id: id })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    return {
        addKeyValue: addKeyValue,
        getKeyValueTypes: getKeyValueTypes,
        getKeyValues: getKeyValues,
        setProcessing: setProcessing,
        keyValueType: keyValueType,
        keyValues: keyValues,
        keyValueTypes: keyValueTypes,
        keyValue: keyValue,
        setKeyValue: setKeyValue,
        setKeyValues: setKeyValues,
        setKeyValueType: setKeyValueType,
        setKeyValueTypes: setKeyValueTypes
    };
};
