var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Layout, { Content } from 'antd/lib/layout/layout';
import { ApplicationHeader } from 'layout/header';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'Routes';
import { withObserver } from 'utilities';
import './ant-design-theme.less';
import styles from './App.module.scss';
var App = function () { return (_jsx(Router, { children: _jsxs(Layout, __assign({ id: "layout", className: styles.layout }, { children: [_jsx(ApplicationHeader, {}, void 0), _jsx(Content, __assign({ className: styles.content }, { children: _jsx(Routes, {}, void 0) }), void 0)] }), void 0) }, void 0)); };
export default withObserver(App);
