var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { organisationRepository } from 'organisation';
import { teamRepository } from 'team';
import { loaderStore } from 'shared/loader';
import { v4 } from 'uuid';
export var OrganisationListStore = function () { return ({
    history: {},
    initialized: false,
    loading: false,
    searchFilter: { name: '', city: '' },
    query: { page: 0, pageSize: 10, sort: 'name', order: 'ASC', filter: '' },
    addOrganisationModalVisible: false,
    count: 0,
    organisations: [],
    teams: [],
    onChangeInput: function (value, field) {
        return __awaiter(this, void 0, void 0, function () {
            var newQuery, organisations;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setSearchFilter(__assign(__assign({}, this.searchFilter), (_a = {}, _a[field] = value.replace(/'/g, '\'\''), _a)));
                        newQuery = __assign(__assign({}, this.query), { page: 0, filter: "name LIKE '%" + this.searchFilter.name + "%'and city LIKE '%" + this.searchFilter.city + "%'" });
                        this.setQuery(newQuery);
                        return [4 /*yield*/, organisationRepository.getMany(newQuery)];
                    case 1:
                        organisations = _b.sent();
                        this.setOrganisations(organisations);
                        return [2 /*return*/];
                }
            });
        });
    },
    onAdd: function (newOrganisation) {
        return __awaiter(this, void 0, void 0, function () {
            var organisationsAdded;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newOrganisation.id = v4();
                        return [4 /*yield*/, organisationRepository.add(newOrganisation)];
                    case 1:
                        organisationsAdded = _a.sent();
                        this.setOrganisations(__spreadArray(__spreadArray([], this.organisations, true), organisationsAdded, true));
                        this.setVisible(false);
                        return [2 /*return*/];
                }
            });
        });
    },
    onCancel: function () {
        this.setVisible(false);
    },
    onTableChange: function (pageSize, current, sort, order) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (pageSize) {
                            this.query.pageSize = pageSize;
                        }
                        if (current) {
                            this.setQuery(__assign(__assign({}, this.query), { page: current - 1 }));
                        }
                        if (sort) {
                            this.setQuery(__assign(__assign({}, this.query), { sort: sort }));
                        }
                        if (order) {
                            this.setQuery(__assign(__assign({}, this.query), { order: order }));
                        }
                        _a = this;
                        return [4 /*yield*/, organisationRepository.getMany(this.query)];
                    case 1:
                        _a.organisations = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
    setHistory: function (history) {
        this.history = history;
    },
    setQuery: function (query) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.query = query;
                        _a = this.setOrganisations;
                        return [4 /*yield*/, organisationRepository.getMany(query)];
                    case 1:
                        _a.apply(this, [_c.sent()]);
                        _b = this;
                        return [4 /*yield*/, organisationRepository.getCount()];
                    case 2:
                        _b.count = _c.sent();
                        this.history.push("/organisations?page=" + query.page + "&sort=" + query.sort + "&order=" + query.order);
                        return [2 /*return*/];
                }
            });
        });
    },
    setSearchFilter: function (filter) {
        this.searchFilter = filter;
    },
    setOrganisations: function (organisations) {
        this.organisations = organisations;
    },
    setTeams: function (teams) {
        this.teams = teams;
    },
    setVisible: function (value) {
        this.addOrganisationModalVisible = value;
    },
    setLoading: function (loading) {
        loaderStore.setLoading(loading);
    },
    setInitialized: function (initializedState) {
        this.initialized = initializedState;
    },
    onInitialize: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setLoading(true);
                        this.setVisible(false);
                        _a = this.setTeams;
                        return [4 /*yield*/, teamRepository.getAll()];
                    case 1:
                        _a.apply(this, [_b.sent()]);
                        this.setInitialized(true);
                        this.setLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    }
}); };
