var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import styles from './key-value-management.module.scss';
import { Button, Checkbox, Input, Select, Table, notification } from 'antd';
import { UseKeyValueManagement as useKeyValueManagement, validationSchema } from './use-key-value-management';
import { ValidationError } from 'yup';
import FormItem from 'antd/lib/form/FormItem';
export var Component = function () {
    var _a, _b;
    var _c = useKeyValueManagement(), addKeyValue = _c.addKeyValue, getKeyValueTypes = _c.getKeyValueTypes, getKeyValues = _c.getKeyValues, setProcessing = _c.setProcessing, keyValueType = _c.keyValueType, keyValues = _c.keyValues, keyValueTypes = _c.keyValueTypes, keyValue = _c.keyValue, setKeyValue = _c.setKeyValue, setKeyValues = _c.setKeyValues, setKeyValueType = _c.setKeyValueType, setKeyValueTypes = _c.setKeyValueTypes;
    var keyValueColumns = useMemo(function () { return [
        {
            title: 'Naam',
            dataIndex: 'name',
            sorter: true,
            key: 'name',
            width: '50%'
        },
        {
            title: 'Gearchiveerd',
            dataIndex: 'isArchived',
            sorter: true,
            key: 'isArchived',
            render: (function (isArchived) { return (_jsx(Checkbox, { checked: isArchived, disabled: true }, void 0)); }),
            width: '50%'
        }
    ]; }, []);
    useEffect(function () {
        var getData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = setKeyValueTypes;
                        return [4 /*yield*/, getKeyValueTypes()];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        }); };
        getData();
    }, []);
    useEffect(function () {
        if (!keyValueType && keyValueTypes && keyValueTypes.length > 0) {
            setKeyValueType(keyValueTypes[0]);
        }
        setProcessing(false);
    }, [keyValueTypes]);
    useEffect(function () {
        if (keyValueType) {
            var getData = function () { return __awaiter(void 0, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = setKeyValues;
                            return [4 /*yield*/, getKeyValues(keyValueType.id)];
                        case 1:
                            _a.apply(void 0, [_b.sent()]);
                            setKeyValue({
                                id: '',
                                name: '',
                                keyValueTypeDataId: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id,
                                isArchived: false
                            });
                            return [2 /*return*/];
                    }
                });
            }); };
            getData();
        }
    }, [keyValueType]);
    if (!keyValueTypes || keyValueTypes.length === 0) {
        return _jsx("div", { children: "Er zijn geen keuzelijsten gevonden" }, void 0);
    }
    var handleSubmit = function (formEvent) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, formValues, isArchived, result, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    formData = new FormData(formEvent.target);
                    formValues = Object.fromEntries(formData.entries());
                    isArchived = false;
                    if (formValues.isArchived !== undefined) {
                        isArchived = formValues.isArchived === 'true' || formValues.isArchived === 'on';
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 9, , 10]);
                    return [4 /*yield*/, validationSchema.validate(__assign(__assign({}, formValues), { isArchived: isArchived }), { strict: true })];
                case 2:
                    result = _b.sent();
                    if (!isNew) return [3 /*break*/, 4];
                    return [4 /*yield*/, addKeyValue({
                            id: '',
                            name: result.name,
                            keyValueTypeDataId: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id,
                            isArchived: false
                        })];
                case 3:
                    _b.sent();
                    setKeyValue({
                        id: '',
                        name: '',
                        keyValueTypeDataId: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id,
                        isArchived: false
                    });
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, addKeyValue(keyValue)];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    if (!keyValueType) return [3 /*break*/, 8];
                    _a = setKeyValues;
                    return [4 /*yield*/, getKeyValues(keyValueType.id)];
                case 7:
                    _a.apply(void 0, [_b.sent()]);
                    _b.label = 8;
                case 8: return [3 /*break*/, 10];
                case 9:
                    error_1 = _b.sent();
                    notification.error({
                        message: 'Er is een fout opgetreden',
                        description: error_1 instanceof ValidationError ? error_1.errors.map(function (x) { return x; }) : error_1,
                        duration: 5
                    });
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }); };
    var isNew = keyValue.id.length === 0;
    return (_jsx("div", __assign({ className: styles['table-container'] }, { children: _jsxs("div", __assign({ className: styles['table-search-container'] }, { children: [_jsx(Select, __assign({ placeholder: 'Selecteer een onderwerp...', value: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id, className: styles['table-picker'], onChange: function (id) { return __awaiter(void 0, void 0, void 0, function () {
                        var selected;
                        return __generator(this, function (_a) {
                            selected = keyValueTypes.find(function (x) { return x.id === id; });
                            setKeyValueType(selected);
                            return [2 /*return*/];
                        });
                    }); } }, { children: keyValueTypes.map(function (option) { return _jsx(Select.Option, __assign({ value: option.id }, { children: option.name }), option.id); }) }), void 0), _jsxs("form", __assign({ onSubmit: function (e) {
                        e.preventDefault();
                        handleSubmit(e);
                    } }, { children: [_jsx("div", __assign({ className: styles['table-container'] }, { children: _jsx(Table, { onRow: function (row) { return ({
                                    onClick: function (e) {
                                        e.preventDefault();
                                        var selected = keyValues === null || keyValues === void 0 ? void 0 : keyValues.find(function (o) { return o.id === row.id; });
                                        if (selected) {
                                            setKeyValue(selected);
                                        }
                                    }
                                }); }, pagination: false, showSorterTooltip: false, columns: keyValueColumns, dataSource: keyValues, rowKey: function (o) { return o.id; } }, void 0) }), void 0), _jsx(Button, __assign({ type: 'primary', onClick: function (e) {
                                e.preventDefault();
                                setKeyValue({
                                    id: '',
                                    name: '',
                                    keyValueTypeDataId: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id,
                                    isArchived: false
                                });
                            } }, { children: isNew ? 'Nieuwe waardes aanmaken' : 'Bestaande waardes aanpassen' }), void 0), (keyValue || isNew) && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles['input-container'] }, { children: [_jsx(FormItem, __assign({ label: "Naam", labelCol: { span: 24 } }, { children: _jsx(Input, { name: "name", disabled: !isNew && !keyValue.name, allowClear: true, placeholder: "Naam...", className: styles['table-picker'], value: (_a = keyValue.name) !== null && _a !== void 0 ? _a : '', onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        e.preventDefault();
                                                        setKeyValue({ id: keyValue.id, name: e.target.value, keyValueTypeDataId: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id, isArchived: keyValue.isArchived });
                                                        return [2 /*return*/];
                                                    });
                                                }); } }, void 0) }), void 0), _jsx(FormItem, __assign({ label: "Gearchiveerd", labelCol: { span: 24 } }, { children: _jsx(Checkbox, { name: "isArchived", disabled: !isNew && !keyValue.name, checked: (_b = keyValue.isArchived) !== null && _b !== void 0 ? _b : false, onChange: function (e) {
                                                    e.preventDefault();
                                                    setKeyValue({ id: keyValue.id, name: keyValue.name, keyValueTypeDataId: keyValueType === null || keyValueType === void 0 ? void 0 : keyValueType.id, isArchived: e.target.checked });
                                                } }, void 0) }), void 0)] }), void 0), _jsx("button", __assign({ className: "ant-btn ant-btn-primary" }, { children: isNew ? 'Toevoegen' : 'Opslaan' }), void 0)] }, void 0))] }), void 0)] }), void 0) }), void 0));
};
