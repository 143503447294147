import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Forbidden, Login } from 'auth';
import { Home } from 'home';
import { AnnualrealisationList } from 'annualrealisation/annualrealisation-list';
import { Route, Switch } from 'react-router-dom';
import { Realisation } from 'organisations/realisation';
import { OrganisationList } from 'organisation/organisation-list';
import { CustomerRealisation } from 'organisations/customer-realisation';
import { RealisationVerification } from 'auth/realisation-verification';
import { Management } from './management';
import { RequireAuth } from 'auth/require-auth';
export var Routes = function () { return (_jsxs(Switch, { children: [_jsx(Route, { path: "/", exact: true, component: function () { return _jsx(Home, {}, void 0); } }, void 0), _jsx(Route, { path: "/organisations", exact: true, component: function () { return _jsx(RequireAuth, { children: _jsx(OrganisationList, {}, void 0) }, void 0); } }, void 0), _jsx(Route, { path: "/login", component: Login }, void 0), _jsx(Route, { path: "/annualrealisations", exact: true, component: function () { return _jsx(RequireAuth, { children: _jsx(AnnualrealisationList, {}, void 0) }, void 0); } }, void 0), _jsx(Route, { path: "/management", exact: true, component: function () { return _jsx(RequireAuth, { children: _jsx(Management, {}, void 0) }, void 0); } }, void 0), _jsx(Route, { path: "/forbidden", component: Forbidden }, void 0), _jsx(Route, { path: "/organisations/:afasid/years/:year/realisation", component: function () { return _jsx(RequireAuth, { children: _jsx(Realisation, {}, void 0) }, void 0); } }, void 0), _jsx(Route, { path: "/customerrealisations/:afasid/year/:year/realisation", component: function () { return _jsx(CustomerRealisation, {}, void 0); } }, void 0), _jsx(Route, { path: "/realisationVerification/:jwtkey", exact: true, component: function () { return _jsx(RealisationVerification, {}, void 0); } }, void 0)] }, void 0)); };
