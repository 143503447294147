var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ExportExcelStore } from './export-excel.store';
import { useStore } from 'utilities';
import styles from './export-excel.module.scss';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
var Component = function (_a) {
    var exportName = _a.exportName, visible = _a.visible;
    var exportExcel = useStore(ExportExcelStore).exportExcel;
    var handleExportExcel = useCallback(function () {
        exportExcel(exportName);
    }, [exportExcel, exportName]);
    return (_jsx(Button, __assign({ style: visible ? { display: 'visible' } : { display: 'none' }, onClick: handleExportExcel, className: styles['navigation-item'], type: "primary", shape: "round", icon: _jsx(FileExcelOutlined, {}, void 0) }, { children: "Excel" }), void 0));
};
export default Component;
