var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            error: undefined,
            errorInfo: undefined,
            hasError: false
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return {
            hasError: true,
            error: error
        };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error({ error: error, errorInfo: errorInfo
        });
        this.setState(__assign(__assign({}, this.state), { errorInfo: errorInfo, error: error }));
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.state, hasError = _a.hasError, errorInfo = _a.errorInfo, error = _a.error;
        if (hasError) {
            return (_jsxs("div", { children: [_jsx("div", { children: _jsxs("p", { children: ["An error has occurred in this component.", ' ', _jsx("span", __assign({ style: { cursor: 'pointer',
                                        color: '#0077FF' }, onClick: function () {
                                        window.location.reload();
                                    } }, { children: "Reload this page" }), void 0), ' '] }, void 0) }, void 0), _jsx("div", { children: _jsxs("details", { children: [_jsx("summary", { children: "Click for error details" }, void 0), _jsx("div", { children: error && error.message }, void 0), _jsx("div", { children: errorInfo &&
                                        errorInfo.componentStack.toString() }, void 0)] }, void 0) }, void 0)] }, void 0));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
