// eslint-disable-next-line no-process-env
var env = process.env;
var nodeEnvironment = env.NODE_ENV.trim();
/**
 * The environment information
 */
export var environment = {
    production: nodeEnvironment === 'production',
    development: nodeEnvironment === 'development',
    name: nodeEnvironment,
    getValue: function (key) { return env[key.toString()]; }
};
