var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './organisation-add.module.scss';
import { Modal, Input, Button, Form as Antform, AutoComplete } from 'antd';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { v4 } from 'uuid';
var FormItem = Antform.Item;
var Component = function (props) {
    var validationSchema = yup.object({}).shape({
        name: yup.string().trim().required('Dit veld is verplicht').max(50, 'De naam van het bedrijf is te lang').min(3, 'De naam van het bedrijf is te kort'),
        city: yup.string().trim().required('Dit veld is verplicht').max(50, 'De naam van de vestigingsplaats is te lang').min(2, 'De naam van de vestigingsplaats is te kort'),
        team: yup.string().trim().required('Dit veld is verplicht').test('Test naam team', 'Team moet er een uit de lijst zijn', function (val) { return props.teams.findIndex(function (x) { return x.name === (val === null || val === void 0 ? void 0 : val.toString()); }) >= 0; }),
        afas: yup.number().required('Dit veld is verplicht').test('len', 'Het AFAS Organisatie-ID bestaat uit 7 cijfers', function (val) { return (val === null || val === void 0 ? void 0 : val.toString().length) === 7; })
    });
    return (_jsx(Modal, __assign({ className: styles.root, visible: props.visible, title: "Create a new collection", okText: "Create", cancelText: "Cancel", onCancel: props.onCancel, onOk: function () {
        }, footer: _jsx("div", {}, void 0) }, { children: _jsx(Formik, __assign({ validateOnChange: true, initialValues: { id: v4(), name: '', city: '', afas: undefined, added: true, team: '' }, validationSchema: validationSchema, onSubmit: function (data, _a) {
                var setSubmitting = _a.setSubmitting, resetForm = _a.resetForm;
                setSubmitting(true);
                resetForm();
                if (validationSchema.isValidSync(data)) {
                    props.onAdd(data);
                }
                setSubmitting(false);
            } }, { children: function (_a) {
                var values = _a.values, isSubmitting = _a.isSubmitting, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, errors = _a.errors, setFieldValue = _a.setFieldValue;
                return (_jsxs(Form, __assign({ onSubmit: handleSubmit }, { children: [_jsx(FormItem, __assign({ label: "Bedrijfsnaam", labelCol: { span: 24 }, help: errors.name }, { children: _jsx(Input, { name: "name", placeholder: "Bedrijfsnaam", value: values.name, onChange: handleChange }, void 0) }), void 0), _jsx(FormItem, __assign({ label: "Vestigingsplaats", labelCol: { span: 24 }, help: errors.city }, { children: _jsx(Input, { name: "city", placeholder: "Vestigingsplaats", value: values.city, onChange: handleChange }, void 0) }), void 0), _jsx(FormItem, __assign({ label: "Team", labelCol: { span: 24 }, help: errors.team }, { children: _jsx(AutoComplete, { options: props.teams.map(function (team) { return ({
                                    value: team.name,
                                    label: team.name
                                }); }), value: values.team, onChange: function (v) { return setFieldValue('team', v); }, placeholder: "Teamnaam", filterOption: function (inputValue, option) {
                                    return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                } }, void 0) }), void 0), _jsx(FormItem, __assign({ label: "AFAS Organisatie-ID", labelCol: { span: 24 }, help: errors.afas }, { children: _jsx(Input, { name: "afas", placeholder: "AFAS Organisatie-ID", value: values.afas, onChange: handleChange }, void 0) }), void 0), _jsx(Button, __assign({ disabled: isSubmitting, htmlType: "button", onClick: props.onCancel, className: styles['cancel-button'] }, { children: "Annuleren" }), void 0), _jsx(Button, __assign({ disabled: isSubmitting, type: "primary", htmlType: "submit" }, { children: "Organisatie Toevoegen" }), void 0)] }), void 0));
            } }), void 0) }), void 0));
};
export default Component;
