var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './export-excel.module.scss';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { utils, write } from 'xlsx';
import FileSaver from 'file-saver';
import { dateFormatterDayMonthYear } from 'utilities/formatters';
import { isNumeric, isValidDate } from 'utilities/helpers/helper';
var Component = function (_a) {
    var exportName = _a.exportName, annualrealisations = _a.annualrealisations;
    var exportExcel = function (customerName) {
        var fileName = customerName + ".xlsx";
        var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        var result = annualrealisations.map(function (r) {
            var _a;
            var _b, _c, _d, _e, _f, _g, _h, _j;
            return (_a = {
                    Jaargang: r.year,
                    Bedrijfsnaam: (_b = r.organisationName) !== null && _b !== void 0 ? _b : '',
                    Team: (_c = r.team) !== null && _c !== void 0 ? _c : '',
                    Projectleider: (_d = r.manager) !== null && _d !== void 0 ? _d : ''
                },
                _a['Uitvoerend consultant'] = (_e = r.consultant) !== null && _e !== void 0 ? _e : '',
                _a['Interne status realisatie'] = (_f = r.internalProgressStatus) !== null && _f !== void 0 ? _f : '',
                _a['Interne status BSN'] = (_g = r.internalStatusBSN) !== null && _g !== void 0 ? _g : '',
                _a['BSN Melder'] = (_h = r.submittedBSNBy) !== null && _h !== void 0 ? _h : '',
                _a['BSN Datum'] = r.submittedRealisationAt ? r.submittedRealisationAt : '',
                _a['Realisatie Melder'] = r.submittedRealisationBy,
                _a['Realisatie Datum'] = r.submittedRealisationAt ? r.submittedRealisationAt : '',
                _a.Opmerking = (_j = r.comment) !== null && _j !== void 0 ? _j : '',
                _a);
        });
        var ws = utils.json_to_sheet(result);
        // Set cell type to date for date columns
        var dateNumberColumns = ['Jaargang', 'BSN Datum', 'Realisatie Datum'];
        dateNumberColumns.forEach(function (col) {
            for (var R = 1; R <= result.length + 1; R++) { // Start from 1 to skip header                
                var cellAddress = utils.encode_cell({ c: Object.keys(result[0]).indexOf(col), r: R });
                if (ws[cellAddress]) {
                    var cellObject = ws[cellAddress];
                    var cellValue = cellObject.v + '';
                    if (cellValue.length > 0 && isValidDate(cellValue)) {
                        cellObject.t = 'd'; // Set cell type to date
                        cellObject.w = dateFormatterDayMonthYear(cellObject.v);
                    }
                    else if (isNumeric(cellValue)) {
                        cellObject.t = 'n'; // Set cell type to number
                        cellObject.z = 0; // Set number format to 0
                        cellObject.v = Number(cellValue);
                    }
                }
            }
        });
        var wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        var excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        var data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    };
    var handleExportExcel = useCallback(function () {
        exportExcel(exportName);
    }, [exportExcel, exportName]);
    return (_jsx(Button, __assign({ onClick: handleExportExcel, className: styles['navigation-item'], type: "primary", shape: "round", icon: _jsx(FileExcelOutlined, {}, void 0) }, { children: "Excel" }), void 0));
};
export default Component;
