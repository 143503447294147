import moment from 'moment';
import { RealisationStore } from 'organisations/realisation/realisation.store';
import * as yup from 'yup';
export var AddPeriodeStore = function () { return ({
    getValidationSchema: (function () {
        var checkForDublicates = function (input) {
            var _a;
            var index = (_a = RealisationStore.applications) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.SONumber === input; });
            if (index !== undefined) {
                return true;
            }
            return false;
        };
        return yup.object({}).shape({
            start: yup.date().nullable(true).required('Dit veld is verplicht').default(null),
            end: yup.date(),
            sonumber: yup.string().test('len', 'Het SO-Nummer bestaat uit 6 cijfers', function (val) { return (val === null || val === void 0 ? void 0 : val.toString().length) === 6; }).required('Dit veld is verplicht')
                .test('test-existing-so-number', 'SO-nummer bestaat al', function (value) { return value !== undefined && checkForDublicates(value.toString()) !== true; })
        });
    }),
    getDisabledDate: function (year, current, startDate) {
        if (!current) {
            return true;
        }
        var usedMonths = RealisationStore.applications.map(function (application) { return moment(application.StartDate).format('YYYY-MM'); });
        var isUsed = usedMonths.findIndex(function (date) { return date === moment(current).format('YYYY-MM'); });
        var isHistoryYear = moment(current).format('YYYY') < moment("1-1-" + year).format('YYYY');
        var isFutureYear = moment(current).format('YYYY') > moment("1-1-" + year).format('YYYY');
        var minimumThreeMonths = startDate !== undefined ? moment(startDate).add(2, 'months').isAfter(moment(current)) : false;
        return isUsed !== -1 || isHistoryYear || isFutureYear || minimumThreeMonths && true;
    }
}); };
