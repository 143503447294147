var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 } from 'uuid';
import { loaderStore } from '../../shared/loader';
import { pnoEntityRepository } from '../../pno-entity';
export var PnoEntityManagementStore = function () { return ({
    pnoEntity: {},
    isNew: false,
    pnoEntities: [],
    succesfullySavedStatus: false,
    setProcessing: function (processing) {
        loaderStore.setProcessing(processing);
    },
    setSuccesfullySaved: function (status) {
        this.succesfullySavedStatus = status;
    },
    setLogo: function (logo) {
        this.setPnoEntity(__assign(__assign({}, this.pnoEntity), { logo: logo }));
    },
    setPnoEntity: function (entity) {
        this.pnoEntity = entity;
    },
    setPnoEntities: function (entities) {
        this.pnoEntities = entities;
    },
    setCurrentPnoEntity: function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var entity;
            return __generator(this, function (_a) {
                this.setSuccesfullySaved(false);
                entity = this.pnoEntities.find(function (x) { return x.id === id; });
                if (entity) {
                    this.setPnoEntity(entity);
                }
                return [2 /*return*/];
            });
        });
    },
    setNewPnoEntityMode: function (isNew) {
        this.setSuccesfullySaved(false);
        this.isNew = isNew;
        if (isNew) {
            this.setPnoEntity({ id: '', name: '', logo: '' });
        }
    },
    addPnoEntity: function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var newPnoEntity, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setProcessing(true);
                        this.setSuccesfullySaved(false);
                        newPnoEntity = {
                            id: this.isNew ? v4() : this.pnoEntity.id,
                            name: data.name,
                            logo: data.logo
                        };
                        this.setPnoEntity(newPnoEntity);
                        if (!this.isNew) return [3 /*break*/, 2];
                        this.setPnoEntities(__spreadArray(__spreadArray([], this.pnoEntities, true), [newPnoEntity], false));
                        return [4 /*yield*/, pnoEntityRepository.insert(newPnoEntity)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        index = this.pnoEntities.findIndex(function (x) { return x.id === newPnoEntity.id; });
                        this.pnoEntities[index] = newPnoEntity;
                        return [4 /*yield*/, pnoEntityRepository.update(newPnoEntity)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.setProcessing(false);
                        this.setSuccesfullySaved(true);
                        return [2 /*return*/];
                }
            });
        });
    },
    onInitialize: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.setPnoEntities;
                        return [4 /*yield*/, pnoEntityRepository.get()];
                    case 1:
                        _a.apply(this, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
}); };
