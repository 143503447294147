import FileSaver from 'file-saver';
import { utils, write } from 'xlsx';
import { RealisationStore } from '../realisation.store';
import { dateFormatterYearMonth } from 'utilities/formatters/date-formatters';
export var ExportExcelStore = function () { return ({
    exportExcel: function (customerName) {
        var fileName = customerName + "-report.xlsx";
        var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        var result = RealisationStore.realisations.filter(function (x) { return !x.isArchived; }).map(function (r) {
            var _a;
            var application = RealisationStore.applications.find(function (x) { return x.id === r.applicationDataId; });
            var project = RealisationStore.projects.find(function (x) { return x.id === r.projectDataId; });
            return _a = {
                    Periode: dateFormatterYearMonth(application.StartDate),
                    Kenmerk: project.mark,
                    Projectnaam: project.name
                },
                _a['SO-Nummer'] = application.SONumber,
                _a.ToegekendeUren = r.grantedHours,
                _a.GerealiseerdeUren = r.realisedHours,
                _a.ToegekendeKosten = r.grantedCosts,
                _a.GerealiseerdeKosten = r.realisedCosts,
                _a.ToegekendeUitgaven = r.grantedExpenses,
                _a.GerealiseerdeUitgaven = r.realisedExpenses,
                _a.ProjectID = r.projectDataId,
                _a.ApplicationID = r.applicationDataId,
                _a.AnnualRealisationID = r.annualrealisationDataId,
                _a.ID = r.id,
                _a;
        });
        result.sort(function (a, b) {
            return a.Periode.localeCompare(b.Periode) || a.Kenmerk.localeCompare(b.Kenmerk);
        });
        var ws = utils.json_to_sheet(result);
        var wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        var excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        var data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName);
    }
}); };
