var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import parse from 'html-react-parser';
import { annualrealisationRepository } from '../../annualrealisation';
import moment from 'moment';
import realisationRepository from 'organisations/realisation.repository';
import { pnoEntityRepository } from '../../pno-entity';
import { employeeRepository } from '../../employee';
import { copyToClipboard } from 'utilities/helpers/text';
import { keyValueRepository, keyValueTypeRepository } from 'key-value';
export var RecordManagementStore = function () { return ({
    defaultEmailLanguage: 'Nederlands',
    managers: [],
    employees: [],
    pnoEntities: [],
    billingMethods: [],
    internalProgressStatus: [],
    internalStatusBSN: [],
    selectedBillingMethod: '',
    selectedInternalProgressStatus: '',
    selectedInternalStatusBSN: '',
    comment: '',
    currentEmailTemplate: {},
    emailTemplates: [],
    linkExpirationDate: moment(new Date()).add(14, 'd'),
    maxLinkExperationDate: moment(new Date()).add(14, 'd'),
    emailContent: '',
    showEmailModal: false,
    setBillingMethods: function (billingMethods) {
        this.billingMethods = billingMethods;
    },
    setInternalProgressStatuses: function (internalProgressStatus) {
        this.internalProgressStatus = internalProgressStatus;
    },
    setIntenalStatusBSN: function (internalStatusBSN) {
        this.internalStatusBSN = internalStatusBSN;
    },
    setManagers: function (managers) {
        this.managers = managers;
    },
    setEmployees: function (employees) {
        this.employees = employees.map(function (x) { return x.name; });
    },
    setPnoEntities: function (entities) {
        this.pnoEntities = entities;
    },
    getPnoEntity: function (id) {
        var entity = this.pnoEntities.find(function (x) { return x.id === id; });
        if (entity) {
            return entity;
        }
    },
    setSelectedBillingMethod: function (billingMethod) {
        this.selectedBillingMethod = billingMethod;
    },
    setInternalProgressStatus: function (internalProgressStatus) {
        this.selectedInternalProgressStatus = internalProgressStatus;
    },
    setInternalStatusBSN: function (internalStatusBSN) {
        this.selectedInternalStatusBSN = internalStatusBSN;
    },
    setComment: function (comment) {
        this.comment = comment;
    },
    setEmailTemplates: function (emailTemplates) {
        this.emailTemplates = emailTemplates;
    },
    setCurrentEmailTemplateByLanguage: function (language) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.currentEmailTemplate = this.emailTemplates.find(function (x) { return x.language === language; });
                return [2 /*return*/];
            });
        });
    },
    getEmailTemplateLanguageCodes: function () {
        return this.emailTemplates.map(function (x) { return x.language; });
    },
    setCurrentEmailTemplate: function (emailtemplate) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.currentEmailTemplate = emailtemplate;
                return [2 /*return*/];
            });
        });
    },
    setShowEmailModal: function (visible) {
        this.showEmailModal = visible;
    },
    seedEmailTemplates: function () {
        return __awaiter(this, void 0, void 0, function () {
            var emailtemplates;
            return __generator(this, function (_a) {
                emailtemplates = [
                    {
                        languageCode: 'NL',
                        language: 'Nederlands'
                    },
                    {
                        languageCode: 'EN',
                        language: 'Engels'
                    }
                ];
                this.setEmailTemplates(emailtemplates);
                this.currentEmailTemplate = emailtemplates[0];
                return [2 /*return*/];
            });
        });
    },
    GenerateCustomerEmailTemplate: function (organisationAfasId, realisationYear, validTill) {
        return __awaiter(this, void 0, void 0, function () {
            var realisationVerification, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        realisationVerification = {
                            afasId: organisationAfasId,
                            language: this.currentEmailTemplate.language,
                            validTill: validTill,
                            year: Number(realisationYear)
                        };
                        return [4 /*yield*/, realisationRepository.getEmailLink(realisationVerification)];
                    case 1:
                        result = _a.sent();
                        if (result.length === 0) {
                            return [2 /*return*/, false];
                        }
                        this.generateEmlDownloadFile(result);
                        return [2 /*return*/];
                }
            });
        });
    },
    GenerateCustomerUrl: function (organisationAfasId, realisationYear, validTill) {
        return __awaiter(this, void 0, void 0, function () {
            var realisationVerification, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        realisationVerification = {
                            afasId: organisationAfasId,
                            language: this.currentEmailTemplate.language,
                            validTill: validTill,
                            year: Number(realisationYear)
                        };
                        return [4 /*yield*/, realisationRepository.getUrl(realisationVerification)];
                    case 1:
                        result = _a.sent();
                        if (result.length === 0) {
                            return [2 /*return*/, false];
                        }
                        copyToClipboard(result);
                        this.emailContent = result;
                        this.showEmailModal = true;
                        return [2 /*return*/];
                }
            });
        });
    },
    generateEmlDownloadFile: function (emlBody) {
        var options = {
            replace: function (domNode) {
                if (domNode.attribs && domNode.attribs.class === 'remove') {
                    return '<></>';
                }
            }
        };
        this.emailContent = parse(emlBody, options);
        this.showEmailModal = true;
    },
    onInitialize: function () {
        return __awaiter(this, void 0, void 0, function () {
            var keyValueTypes, keyValues, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, keyValueTypeRepository.get()];
                    case 1:
                        keyValueTypes = _d.sent();
                        return [4 /*yield*/, keyValueRepository.get()];
                    case 2:
                        keyValues = _d.sent();
                        this.setBillingMethods(keyValues.filter(function (x) { var _a; return x.keyValueTypeDataId === ((_a = keyValueTypes.find(function (y) { return y.keyValueTypeReference === 'billingMethod'; })) === null || _a === void 0 ? void 0 : _a.id); }).map(function (z) { return z.name; }));
                        this.setInternalProgressStatuses(keyValues.filter(function (x) { var _a; return x.keyValueTypeDataId === ((_a = keyValueTypes.find(function (y) { return y.keyValueTypeReference === 'internalProgressStatus'; })) === null || _a === void 0 ? void 0 : _a.id); }).map(function (z) { return z.name; }));
                        this.setIntenalStatusBSN(keyValues.filter(function (x) { var _a; return x.keyValueTypeDataId === ((_a = keyValueTypes.find(function (y) { return y.keyValueTypeReference === 'internalStatusBSN'; })) === null || _a === void 0 ? void 0 : _a.id); }).map(function (z) { return z.name; }));
                        _a = this.setManagers;
                        return [4 /*yield*/, annualrealisationRepository.getManagers()];
                    case 3:
                        _a.apply(this, [_d.sent()]);
                        _b = this.setEmployees;
                        return [4 /*yield*/, employeeRepository.getAll()];
                    case 4:
                        _b.apply(this, [_d.sent()]);
                        return [4 /*yield*/, this.seedEmailTemplates()];
                    case 5:
                        _d.sent();
                        _c = this.setPnoEntities;
                        return [4 /*yield*/, pnoEntityRepository.get()];
                    case 6:
                        _c.apply(this, [_d.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
}); };
