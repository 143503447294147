var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { Fragment, useCallback, useMemo } from 'react';
import { ConfigProvider, Input, Table, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { DebounceInput } from 'react-debounce-input';
import styles from './annualrealisation-input-sheet.module.scss';
import { dateFormatterMonthYear } from 'utilities/formatters/date-formatters';
import { DeleteOutlined } from '@ant-design/icons';
import { proxyToJs } from 'utilities';
import { normalizeFormattedNumber } from 'utilities/formatters/number-formatters';
function renderAdditionalTotalRows(row, index, props) {
    var _a, _b, _c;
    return (_jsxs(Table.Summary.Row, { children: [_jsx(Table.Summary.Cell, __assign({ className: styles.entitledSummary, index: props.type, colSpan: (props.applications.length * 2) + 3 }, { children: row.title }), void 0), _jsx(Table.Summary.Cell, __assign({ className: styles.entitledSummary + " " + (((_a = row.errors) === null || _a === void 0 ? void 0 : _a.length) ? styles.error : '') + " " + (row.highlightValue ? styles.reportedTotal : ''), align: "right", index: 2 }, { children: _jsx(Tooltip, __assign({ title: (_b = row.errors) === null || _b === void 0 ? void 0 : _b.join(', '), color: 'red' }, { children: (_c = row.value) === null || _c === void 0 ? void 0 : _c.toLocaleString(undefined, { minimumFractionDigits: 0 }) }), void 0) }), void 0), _jsx(Table.Summary.Cell, { index: 99 }, void 0)] }, row.title + "-" + index));
}
export var TableType;
(function (TableType) {
    TableType[TableType["Hours"] = 0] = "Hours";
    TableType[TableType["Costs"] = 1] = "Costs";
    TableType[TableType["Expenses"] = 2] = "Expenses";
    TableType[TableType["CostsExpenses"] = 3] = "CostsExpenses";
    TableType[TableType["BigExpenses"] = 4] = "BigExpenses";
})(TableType || (TableType = {}));
export var Mode;
(function (Mode) {
    Mode[Mode["Default"] = 0] = "Default";
    Mode[Mode["CustomerRealisation"] = 1] = "CustomerRealisation";
})(Mode || (Mode = {}));
var Component = function (props) {
    var applications = props.applications, type = props.type, tableData = props.tableData, onInputChange = props.onInputChange, customerAccepted = props.customerAccepted, grantedFieldReadOnly = props.grantedFieldReadOnly, onDeleteProject = props.onDeleteProject, filterProject = props.filterProject, filterValue = props.filterValue, rowsTitle = props.rowsTitle, _a = props.hideTotalRows, hideTotalRows = _a === void 0 ? false : _a, _b = props.additionalTotalRows, additionalTotalRows = _b === void 0 ? [] : _b;
    var fields = useMemo(function () {
        switch (type) {
            case TableType.Hours:
                return [
                    { title: 'Toegekend', field: 'grantedHours', readonly: grantedFieldReadOnly, disabled: (props.mode === Mode.Default ? false : true) },
                    { title: 'Gerealiseerd', field: 'realisedHours', errorsField: 'errorsHours', disabled: false }
                ];
            case TableType.Costs:
                return [
                    { title: 'Toegekend', field: 'grantedCosts', readonly: grantedFieldReadOnly, disabled: false },
                    { title: 'Gerealiseerd', field: 'realisedCosts', errorsField: 'errorsCosts', disabled: false }
                ];
            case TableType.Expenses:
                return [
                    { title: 'Toegekend', field: 'grantedExpenses', readonly: grantedFieldReadOnly, disabled: false },
                    { title: 'Gerealiseerd', field: 'realisedExpenses', errorsField: 'errorsExpenses', disabled: false }
                ];
            case TableType.CostsExpenses:
                return [
                    { title: 'Toegekend', field: 'grantedCostsExpenses', readonly: true, disabled: false },
                    { title: 'Gerealiseerd', field: 'realisedExpenses', readonly: true, disabled: false }
                ];
            case TableType.BigExpenses:
                return [
                    { title: 'Toegekend', field: 'grantedBigExpenses', readonly: grantedFieldReadOnly, disabled: false },
                    { title: 'Gerealiseerd', field: 'realisedBigExpenses', errorsField: 'errorsBigExpenses', disabled: false }
                ];
            default:
                return [];
        }
    }, [type]);
    var handleInputChange = useCallback(function (key, event, record) {
        event.target.value = normalizeFormattedNumber(event.target.value);
        onInputChange === null || onInputChange === void 0 ? void 0 : onInputChange(key, event, record);
    }, [onInputChange]);
    return (_jsx("div", __assign({ style: styles }, { children: _jsx(ConfigProvider, __assign({ renderEmpty: function () { return null; } }, { children: _jsxs(Table, __assign({ className: styles.annualrealisationInputSheet, rowKey: "key", dataSource: proxyToJs(tableData), pagination: false, summary: function () {
                    var _a, _b, _c;
                    return (_jsxs(Table.Summary, { children: [!hideTotalRows && (_jsxs(Table.Summary.Row, { children: [_jsx(Table.Summary.Cell, __assign({ className: styles.summary, index: type }, { children: "Totaal" }), void 0), applications.map(function (application, applicationIndex) {
                                        var _a, _b;
                                        return (_jsxs(Fragment, { children: [_jsx(Table.Summary.Cell, __assign({ className: styles.summary + " " + styles["application" + (applicationIndex + 1)], align: "right", index: 0 }, { children: (_a = application["granted" + TableType[type]]) === null || _a === void 0 ? void 0 : _a.toLocaleString(undefined, { minimumFractionDigits: 0 }) }), void 0), _jsx(Table.Summary.Cell, __assign({ className: styles.summary + " " + styles["application" + (applicationIndex + 1)], align: "right", index: 0 }, { children: (_b = application["realised" + TableType[type]]) === null || _b === void 0 ? void 0 : _b.toLocaleString(undefined, { minimumFractionDigits: 0 }) }), void 0)] }, application.id));
                                    }), _jsxs(_Fragment, { children: [_jsx(Table.Summary.Cell, __assign({ className: styles.summary + " " + styles.total, align: "right", index: 0 }, { children: (_a = props.grantedTotal) === null || _a === void 0 ? void 0 : _a.toLocaleString(undefined, { minimumFractionDigits: 0 }) }), void 0), _jsx(Table.Summary.Cell, __assign({ className: styles.summary + " " + styles.total, align: "right", index: 1 }, { children: (_b = props.realisedTotal) === null || _b === void 0 ? void 0 : _b.toLocaleString(undefined, { minimumFractionDigits: 0 }) }), void 0), props.mode !== Mode.CustomerRealisation && (_jsxs(_Fragment, { children: [_jsx(Table.Summary.Cell, __assign({ className: styles.summary + " " + styles.total + " " + (props.highlightReportedTotal ? styles.reportedTotal : ''), align: "right", index: 2 }, { children: (_c = props.reportedTotal) === null || _c === void 0 ? void 0 : _c.toLocaleString(undefined, { minimumFractionDigits: 0 }) }), void 0), _jsx(Table.Summary.Cell, { index: 99 }, void 0)] }, void 0))] }, void 0)] }, void 0)), props.mode !== Mode.CustomerRealisation && (_jsxs(_Fragment, { children: [additionalTotalRows === null || additionalTotalRows === void 0 ? void 0 : additionalTotalRows.map(function (row, index) { return renderAdditionalTotalRows(row, index, props); }), props.entitledTotal !== undefined ? renderAdditionalTotalRows({
                                        title: 'Uiteindelijk recht',
                                        value: props.entitledTotal,
                                        errors: props.entitledTotalErrors
                                    }, additionalTotalRows.length, props) : null] }, void 0))] }, void 0));
                } }, { children: [_jsx(Table.Column, { className: styles.titleColumn, title: rowsTitle && (_jsxs("div", { children: [_jsx("div", { children: rowsTitle }, void 0), filterProject && (_jsx(Input, { placeholder: 'filter op project...', value: filterValue, className: styles.filterInput, onChange: function (e) { return filterProject(e.target.value); } }, void 0))] }, void 0)), dataIndex: "name", render: function (projectName, _record) { return (_jsxs("div", __assign({ className: styles.projectinfo }, { children: [_jsx("div", __assign({ className: styles.mark }, { children: _record.mark }), void 0), _jsx("div", __assign({ className: styles.name }, { children: projectName }), void 0)] }), void 0)); } }, void 0), applications.reduceRight(function (children, application, applicationIndex) { return (_jsx(Table.ColumnGroup, __assign({ title: _jsxs("div", { children: [_jsx("div", { children: dateFormatterMonthYear(application.StartDate) }, void 0), _jsx("div", { children: application.SONumber }, void 0)] }, void 0), className: styles["application" + (applicationIndex + 1)] }, { children: _jsxs(_Fragment, { children: [fields.map(function (field) { return (_jsx(Table.Column, { dataIndex: field.field + ';' + application.id, title: field.title, className: styles["application" + (applicationIndex + 1)], 
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    render: function (value, _record) {
                                        var errors = field.errorsField ? _record[field.errorsField + ';' + application.id] : [];
                                        return (_jsx(Tooltip, __assign({ title: errors === null || errors === void 0 ? void 0 : errors.join(', '), color: 'red' }, { children: _jsx(DebounceInput, { onKeyPress: function (x) {
                                                    if (!/^([0-9])$/.test(x.key)) {
                                                        x.preventDefault();
                                                    }
                                                }, debounceTimeout: 2000, forceNotifyOnBlur: true, forceNotifyByEnter: true, readOnly: field.readonly || customerAccepted, unselectable: 'off', className: [
                                                    styles.debounceinput,
                                                    (field === null || field === void 0 ? void 0 : field.readonly) || customerAccepted ? styles.debounceinputReadonly : '',
                                                    (errors === null || errors === void 0 ? void 0 : errors.length) ? styles.debounceinputError : ''
                                                ].join(' '), value: Number(value).toLocaleString(undefined, { minimumFractionDigits: 0 }), onChange: function (e) { return handleInputChange([field.field] + ';' + application.id, e, _record); } }, void 0) }), void 0));
                                    } }, field.field + ';' + application.id)); }), children] }, void 0) }), application.id)); }, _jsx(_Fragment, {}, void 0)), _jsxs(Table.ColumnGroup, __assign({ title: "Totalen", className: styles.total }, { children: [_jsx(Table.Column, { title: "Toegekend", align: "right", className: styles.total, dataIndex: 'totalCountGranted' + TableType[type], render: function (value) { return (value === null || value === void 0 ? void 0 : value.toLocaleString(undefined, { minimumFractionDigits: 0 })); } }, void 0), _jsx(Table.Column, { title: "Gerealiseerd", align: "right", className: styles.total, dataIndex: 'totalCountRealised' + TableType[type], render: function (value) { return (value === null || value === void 0 ? void 0 : value.toLocaleString(undefined, { minimumFractionDigits: 0 })); } }, void 0), props.mode !== Mode.CustomerRealisation ? (_jsx(Table.Column, { title: "Mededeling", align: "right", className: styles.total, dataIndex: 'totalCountReported' + TableType[type], render: function (value) { return (value === null || value === void 0 ? void 0 : value.toLocaleString(undefined, { minimumFractionDigits: 0 })); } }, void 0)) : (_jsx(_Fragment, {}, void 0))] }), void 0), onDeleteProject && (_jsx(Table.Column, { title: "Acties", dataIndex: "projectId", render: function (id) { return (_jsx("div", __assign({ className: styles.actions }, { children: _jsx(DeleteOutlined, { onClick: function () { return onDeleteProject(id); } }, void 0) }), void 0)); } }, void 0))] }), void 0) }), void 0) }), void 0));
};
export default Component;
