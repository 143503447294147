var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from '../record-management.module.scss';
import FormItem from 'antd/lib/form/FormItem';
import { Select } from 'antd';
import React from 'react';
export var InputSearchfield = function (props) {
    var label = props.label, placeholder = props.placeholder, defaultValue = props.defaultValue, onChange = props.onChange, options = props.options, disabled = props.disabled;
    return (_jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(FormItem, __assign({ label: label, labelCol: { span: 24 } }, { children: _jsx(Select, __assign({ allowClear: true, placeholder: placeholder, defaultValue: defaultValue, className: styles['table-picker'], onChange: onChange, disabled: disabled }, { children: options.map(function (option) { return _jsx(Select.Option, __assign({ value: option }, { children: option }), option); }) }), void 0) }), void 0) }), void 0));
};
