import { authStore } from 'auth';
var createManager = function () {
    var jwtToken;
    /**
     * Gets the jwt token
     * @returns The jwt token
     */
    var getToken = function () { return jwtToken; };
    /**
     * Sets the jwt token
     * @param token The token to set
     */
    var setToken = function (token, isAdmin) {
        jwtToken = token;
        authStore.setToken(token);
        authStore.setIsAdmin(isAdmin);
    };
    return {
        getToken: getToken,
        setToken: setToken
    };
};
export default createManager();
