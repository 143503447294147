import { SubmittedMethod } from '@application/domain';
import moment from 'moment';
export var AnnualrealisationStatusStore = function () { return ({
    showConsultantInputBSN: false,
    showConsultantInputRealisation: false,
    showDateInputRealisation: false,
    showDateInputBSN: false,
    getMomentDate: function (date) {
        var momentDate = !date ? moment(new Date()) : moment(date);
        return momentDate;
    },
    getMethodOfSubmission: function (values, type) {
        if (type === 'realisation') {
            var submittedMethod = values.submittedRealisationMethod;
            switch (Number(submittedMethod)) {
                case SubmittedMethod.None:
                    this.showConsultantInputRealisation = false;
                    this.showDateInputRealisation = false;
                    break;
                case SubmittedMethod.Consultant:
                    this.showConsultantInputRealisation = true;
                    this.showDateInputRealisation = true;
                    break;
                case SubmittedMethod.Customer:
                    this.showConsultantInputRealisation = false;
                    this.showDateInputRealisation = true;
                    break;
                default:
                    this.showConsultantInputRealisation = false;
                    this.showDateInputRealisation = false;
                    break;
            }
            return submittedMethod;
        }
        else {
            var submittedMethod = values.submittedBSNMethod;
            switch (Number(submittedMethod)) {
                case SubmittedMethod.None:
                    this.showConsultantInputBSN = false;
                    this.showDateInputBSN = false;
                    break;
                case SubmittedMethod.Consultant:
                    this.showConsultantInputBSN = true;
                    this.showDateInputBSN = true;
                    break;
                case SubmittedMethod.Customer:
                    this.showConsultantInputBSN = false;
                    this.showDateInputBSN = true;
                    break;
                default:
                    this.showConsultantInputBSN = false;
                    this.showDateInputBSN = false;
                    break;
            }
            return submittedMethod;
        }
    },
    transformToDate: function (momentDate) {
        if (momentDate) {
            var date = momentDate.toDate();
            return date;
        }
        else {
            return;
        }
    }
}); };
