"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmittedMethod = void 0;
var SubmittedMethod;
(function (SubmittedMethod) {
    SubmittedMethod[SubmittedMethod["None"] = 0] = "None";
    SubmittedMethod[SubmittedMethod["Consultant"] = 1] = "Consultant";
    SubmittedMethod[SubmittedMethod["Customer"] = 2] = "Customer";
    SubmittedMethod[SubmittedMethod["Bankrupt"] = 3] = "Bankrupt";
    SubmittedMethod[SubmittedMethod["NoneIndependentGreaterThan500"] = 4] = "NoneIndependentGreaterThan500";
    SubmittedMethod[SubmittedMethod["NotNecessary"] = 5] = "NotNecessary";
})(SubmittedMethod = exports.SubmittedMethod || (exports.SubmittedMethod = {}));
