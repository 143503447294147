var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import debounce from 'lodash/debounce';
import { OrganisationListStore } from './organisation-list.store';
import { OrganisationAdd } from '../organisation-add';
import { useStore } from 'utilities';
import { loaderStore } from 'shared/loader';
import styles from './organisation-list.module.scss';
import { Button, Input, Table } from 'antd';
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { BarChart } from 'shared/charts/bar-chart-percentage-submitted';
import { PieChart } from 'shared/charts/pie-chart-annualrealisation-methods';
import { useEffect, useMemo } from 'react';
import { getSearchParams } from 'utilities/helpers/url';
function toApiSortOrder(order) {
    return order === 'ascend' ? 'ASC' : 'DESC';
}
function toTableSortOrder(order) {
    return (order === 'ASC' ? 'ascend' : 'descend');
}
var Component = function () {
    var store = useStore(OrganisationListStore);
    var history = useHistory();
    useEffect(function () {
        var _a, _b;
        if (!store.initialized) {
            store.setHistory(history);
        }
        var params = getSearchParams(history);
        var currentPage = params.get('page') ? Number(params.get('page')) : 0;
        var sort = (_a = params.get('sort')) !== null && _a !== void 0 ? _a : 'name';
        var order = (_b = params.get('order')) !== null && _b !== void 0 ? _b : 'ASC';
        store.setQuery(__assign(__assign({}, store.query), { page: currentPage, sort: sort, order: order }));
    }, [store, history]);
    var columns = useMemo(function () { return [
        {
            title: 'Bedrijfsnaam',
            dataIndex: 'name',
            sorter: true,
            key: 'name',
            defaultSortOrder: store.query.sort === 'name' ? toTableSortOrder(store.query.order) : undefined
        },
        {
            title: 'Vestigingsplaats',
            dataIndex: 'city',
            sorter: true,
            key: 'city',
            defaultSortOrder: store.query.sort === 'city' ? toTableSortOrder(store.query.order) : undefined
        }
    ]; }, [store.query]);
    return (_jsxs("div", __assign({ className: styles.root }, { children: [loaderStore.loading ? 'De gegevens worden opgehaald...' : (_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles['table-name-container'] }, { children: [_jsx("h2", { children: "Bedrijven" }, void 0), _jsx(Button, __assign({ type: "primary", shape: "round", icon: _jsx(PlusCircleOutlined, {}, void 0), onClick: function () {
                                    store.setVisible(true);
                                } }, { children: "Nieuwe Organisatie Toevoegen" }), void 0), _jsx(OrganisationAdd, { visible: store.addOrganisationModalVisible, teams: store.teams, onAdd: store.onAdd, onCancel: store.onCancel }, void 0)] }), void 0), _jsxs("div", __assign({ className: styles['table-search-container'] }, { children: [_jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(Input, { placeholder: "Zoek op bedrijfsnaam...", onChange: debounce(function (e) { return store.onChangeInput(e.target.value, 'name'); }, 300), defaultValue: store.searchFilter.name, prefix: _jsx(SearchOutlined, {}, void 0) }, void 0) }), void 0), _jsx("div", __assign({ className: styles['input-searchfield-container'] }, { children: _jsx(Input, { placeholder: "Zoek op plaatsnaam...", onChange: debounce(function (e) { return store.onChangeInput(e.target.value, 'city'); }, 300), defaultValue: store.searchFilter.city, prefix: _jsx(SearchOutlined, {}, void 0) }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: styles['table-container'] }, { children: _jsx("div", { children: store.loading ? 'loading...' : (_jsx(Table, { onRow: function (record) { return ({
                                    onClick: function (e) {
                                        e.preventDefault();
                                        history.push("/organisations/" + record.afas + "/years/" + (new Date().getFullYear() - 1) + "/realisation/?tab=1");
                                    }
                                }); }, onChange: function (pagination, _, sorter) {
                                    var sort = Array.isArray(sorter) ? sorter[0] : sorter;
                                    store.onTableChange(pagination.pageSize, pagination.current, sort.columnKey, toApiSortOrder(sort.order));
                                }, columns: columns, dataSource: store.organisations, rowKey: function (o) { return o.id; }, pagination: {
                                    total: store.count,
                                    pageSize: store.query.pageSize,
                                    current: store.query.page + 1
                                } }, void 0)) }, void 0) }), void 0)] }), void 0)), _jsxs("div", __assign({ className: styles['charts-container'] }, { children: [_jsx(BarChart, {}, void 0), _jsx(PieChart, {}, void 0)] }), void 0)] }), void 0));
};
export default Component;
